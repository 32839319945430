<template>
    <vm-container>
        <clere-title>
            Accounts
        </clere-title>
        <div class="mb-3">
            <vm-button primaryColor="var(--triadic2-600)" @click="addAccount()">
                ADD ACCOUNT
            </vm-button>
        </div>
        <vm-card>
            <vm-grid
                :config="gridConfig"
                :rowData="accountSummaries"
                :isLoading="isLoading"
            >
            </vm-grid>
        </vm-card>
    </vm-container>

    <vm-modal ref="accountEditorModal">
        <vm-card-header>Account editor</vm-card-header>
        <vm-card-content>
            <account-editor
                :account="account"
                :saveCallback="saveAccount"
                :closeCallback="hideModal"
            >
            </account-editor>
        </vm-card-content>
    </vm-modal>

    <vm-modal ref="confirmDeleteModal">
        <vm-card-header>Delete account</vm-card-header>

        <vm-card-content>
            <confirm
                :closeModal="hideDeleteModal"
                :confirm="deleteSelectedAccount"
                primaryColor="var(--red-100)"
            >
                Are you sure you want to delete account {{ account.firstName }}
                {{ account.lastName }}?
            </confirm>
        </vm-card-content>
    </vm-modal>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted } from 'vue';

// vue3-material
import { VmModal } from 'vue3-material';

// configs
import { accountsConfig } from '@/config/accounts-config';

// components
import AccountEditor from '@/views/admin/account-editor.vue';
import ClereTitle from '@/components/clere-title.vue';
import Confirm from '@/components/confirm.vue';
import { CrActions } from '@/components/grid-plugins';

// services
import { useAccountService } from '@/api/service/account.service';

// models
import { Account } from '@/api/model/account.model';

export default defineComponent({
    components: {
        AccountEditor,
        ClereTitle,
        Confirm,
    },
    setup() {
        // services
        const {
            accountSummaries,
            getAccountAll,
            getAccountSummaryAll,
            getAccount,
            createAccount,
            updateAccount,
            deleteAccount,
        } = useAccountService();

        // refs
        const accountEditorModal = ref<InstanceType<typeof VmModal>>();
        const confirmDeleteModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const account = ref(new Account());
        const isEditing = ref(false);
        const isLoading = ref(true);

        // methods
        const openModal = () => {
            accountEditorModal.value.show();
        };

        const hideModal = () => {
            accountEditorModal.value.hide();
        };

        const hideDeleteModal = () => {
            confirmDeleteModal.value.hide();
        };

        const saveAccount = async () => {
            if (isEditing.value) {
                await updateAccount(account.value);
                account.value = new Account();
            } else {
                await createAccount(account.value);
                account.value = new Account();
            }
            hideModal();
            isEditing.value = false;
        };

        const editAccount = async (a: Account) => {
            if (a && a.id) {
                const existingAccount = await getAccount(a.id);
                account.value = existingAccount;
                isEditing.value = true;
            } else {
                account.value = new Account();
                isEditing.value = false;
            }
            accountEditorModal.value.show();
        };

        const showAccountDelete = async (a: Account) => {
            if (a && a.id) {
                const existingAccount = await getAccount(a.id);
                account.value = existingAccount;
                confirmDeleteModal.value.show();
            }
        };

        const deleteSelectedAccount = async () => {
            if (account.value.id) {
                await deleteAccount(account.value.id);
                hideDeleteModal();
            }
        };

        const addAccount = async () => {
            account.value = new Account();
            isEditing.value = false;
            openModal();
        };

        // lifecycle hooks
        onMounted(async () => {
            getAccountAll();
            await getAccountSummaryAll();
            isLoading.value = false;
        });

        // configs
        const gridConfig = [
            ...accountsConfig,
            {
                title: 'Actions',
                fitWidth: true,
                cellRenderer: {
                    component: CrActions,
                    exported: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                callback: editAccount,
                            },
                            {
                                icon: 'fal fa-trash-alt',
                                callback: showAccountDelete,
                            },
                        ],
                    },
                },
            },
        ];

        return {
            isEditing,
            accountSummaries,
            gridConfig,
            saveAccount,
            editAccount,
            addAccount,
            deleteAccount,
            deleteSelectedAccount,
            account,
            accountEditorModal,
            confirmDeleteModal,
            openModal,
            hideModal,
            hideDeleteModal,
            isLoading,
        };
    },
});
</script>

<style scoped></style>
