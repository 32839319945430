<template>
    <vm-container>
        <component :is="documentationComponent"></component>
    </vm-container>
</template>

<script lang="ts">
// vue
import { defineAsyncComponent, defineComponent, shallowRef, watch } from 'vue';
import { useRoute } from 'vue-router';

import overviewComponent from './overview_1.0.0.vue';
// import mosaicComponent from './mosaic_1.0.0.vue';

export default defineComponent({
    setup() {
        // hooks
        const route = useRoute();

        // properties
        const documentationComponent = shallowRef(overviewComponent);

        // watchers
        watch(
            () => route.path,
            () => {
                const subject = route.path.split('/')[2];
                if (subject) {
                    const component = defineAsyncComponent(() =>
                        import(`./${subject}_1.0.0.vue`),
                    );
                    documentationComponent.value = component;
                } else {
                    documentationComponent.value = overviewComponent;
                }
            },
        );

        return {
            documentationComponent,
        };
    },
});
</script>

<style scoped></style>
