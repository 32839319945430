<template>
    <div class="dropdown">
        <div
            class="dropdown-title"
            :class="{ 'is-open': isOpen }"
            @click="toggle"
        >
            <div class="arrow">
                <i
                    class="far fa-chevron-right"
                    :class="{ 'rotate-arrow': isOpen }"
                >
                </i>
            </div>
            <span class="ml-3">{{ props.title }}</span>
        </div>
        <div class="dropdown-content" :class="{ hidden: !isOpen }">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: ['title'],
    setup(props: any) {
        // properties
        const isOpen = ref(false);

        // methods
        const toggle = () => {
            isOpen.value = !isOpen.value;
        };

        return {
            props,
            toggle,
            isOpen,
        };
    },
});
</script>

<style scoped>
.dropdown-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    padding: 5px 10px;
    transition-duration: 0.2s;
}

.dropdown-title:hover,
.is-open {
    position: relative;
    z-index: 2;
}

.dropdown-title:hover {
    background: var(--medium-blue);
    transition-duration: 0.2s;
}

.arrow {
    font-size: 0.8rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.arrow i {
    transition-duration: 0.2s;
}

.rotate-arrow {
    transform: rotate(90deg);
}

.hidden {
    display: none;
}

.dropdown-content {
    padding-top: 10px;
    padding-left: 50px;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>
