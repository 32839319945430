<template>
    <div class="chip elevation-2">
        <slot></slot>
        <vm-action-button
            @click="clearFile(index)"
            icon="far fa-times"
            :elevation="null"
            primaryColor="rgba(0,0,0,0)"
            accentColor="#000000"
        >
            <i class="far fa-times"></i>
        </vm-action-button>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['clearFile', 'index'],
    setup() {
        return {};
    },
});
</script>

<style scoped>
.chip {
    border-radius: 10000px;
    padding: 10px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
