<template>
    <form @submit.prevent="saveCallback">
        <input
            autocomplete="off"
            name="hidden"
            type="text"
            style="display:none;"
        />
        <div class="account-form">
            <vm-text-field
                variant="outlined"
                label="Name"
                v-model="props.roleName.name"
            />

            <label class="editor-label mt-4">
                Permissions
            </label>
            <div class="permission-list" v-if="permissions">
                <div
                    class="row mt-3"
                    style="width: 50%"
                    v-for="(permission, i) in permArrayToggle"
                    :key="i"
                >
                    <vm-switch
                        :label="permissionName(permission.name)"
                        v-model="props.permArrayToggle[i].toggle"
                    />
                </div>
            </div>
        </div>

        <div class="row jc-flex-end mt-4">
            <vm-button
                variant="text"
                primaryColor="var(--triadic2-600)"
                @click="closeCallback"
            >
                CANCEL
            </vm-button>
            <vm-button
                class="ml-2"
                primaryColor="var(--triadic2-600)"
                type="submit"
            >
                SAVE
            </vm-button>
        </div>
    </form>
</template>

<script lang="ts">
// vue
import { defineComponent, onMounted } from 'vue';

// services
import { usePermissionService } from '@/api/service/permission.service';

export default defineComponent({
    props: [
        'role',
        'saveCallback',
        'closeCallback',
        'permArrayToggle',
        'roleName',
    ],
    setup(props: any) {
        // services
        const { permissions, getPermissionAll } = usePermissionService();

        // methods
        const permissionName = (name: string) => {
            name = name.substring(3);
            const result = name.replace(/([A-Z])/g, ' $1');
            const finalResult =
                result.charAt(0).toUpperCase() + result.slice(1);
            return finalResult;
        };

        // lifecycle hooks
        onMounted(async () => {
            getPermissionAll();
        });

        return {
            props,
            permissions,
            permissionName,
        };
    },
});
</script>

<style scoped>
.account-form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.input {
    width: 90%;
    padding: 4px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
}

.select {
    width: 90%;
    padding: 4px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
}

.button-container {
    display: flex;
    justify-content: right;
}

.permission-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
</style>
