<template>
    <vm-container>
        <div class="clere-footer">
            ©️ 2021 Clere Group Ltd
        </div>
    </vm-container>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped>
.clere-footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    padding: 20px 0;
    border-top: 1px solid rgba(var(--default-color), 0.2);
    color: rgba(var(--default-color), 0.5);
    margin-top: 50px;
}
</style>
