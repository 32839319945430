<template>
    <div>
        <div class="documentation-header">Satellite Imagery</div>
        <vm-card class="mb-4">
            <vm-card-header>Feather satellite image (mosaic)</vm-card-header>
            <vm-card-content>
                <div class="mb-2">
                    If you are already set up with SSH access into
                    <strong>apollo</strong> then SSH into
                    <strong>apollo</strong>.
                </div>
                <code>
                    <pre>$ ssh apollo</pre>
                </code>
                <div class="mb-2 mt-2">
                    If the file is in the
                    <code class="code-block">clere-golf-hub-raster</code>
                    directory then run the
                    <code class="code-block">./run.sh</code> script with the
                    course schema name to feather the mosaic file for that
                    course. Below is an example with Long Sutton.
                </div>
                <code>
                    <pre>$ ./run.sh long_sutton</pre>
                </code>
            </vm-card-content>
        </vm-card>
        <vm-card class="mb-4">
            <vm-card-header>
                Geoserver - Reduce resolution and reproject into EPSG:3857
                (mosaic)
            </vm-card-header>
            <vm-card-content>
                <div class="mb-2">
                    Navigate to the root directory of the geotools repository
                </div>
                <code class="mb-2">
                    <pre>$ cd /path/to/raster-to-mbtiles/</pre>
                </code>
                <div class="mt-2 mb-2">
                    Move the feathered mosaic TIFF into the data/ directory
                </div>
                <code>
                    <pre>$ mv /path/to/mosaic_feathered.tif .</pre>
                </code>
                <div class="mt-2 mb-2">
                    Process the TIFF using the convert-to-geoserver.sh script
                </div>
                <code class="mb-2">
                    <pre>
$ ./convert-to-geoserver.sh mosaic_feathered.tif mosaic_feathered_3857_20cm.tif</pre
                    >
                </code>
            </vm-card-content>
        </vm-card>
        <vm-card>
            <vm-card-header>
                Mapbox - Convert mosaic into mapbox tiles format
            </vm-card-header>
            <vm-card-content>
                <div class="mb-2">
                    <ol class="documentation-list">
                        <li>
                            Navigate to the root directory of the geotools
                            repository.
                        </li>
                        <li>
                            Move the feathered mosaic TIFF into the data/
                            directory.
                        </li>
                        <li>
                            Process the TIFF using the
                            convert-satellite-mapbox.sh script.
                        </li>
                    </ol>
                </div>
            </vm-card-content>
        </vm-card>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    setup() {
        return {};
    },
});
</script>

<style scoped></style>
