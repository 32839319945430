import { createStore } from 'vuex';
import axios from 'axios';
import { Token } from '@/api/model/token.model';

/* local api */
const url = 'clerenet.uk';
const apiPort = '443';
const apiSubdomain = 'api.';
const apiProtocol = 'https';

/* remote api */
// const url = 'clerenet.uk';
// const apiPort = '443';
// const apiSubdomain = 'api.';
// const apiProtocol = 'https';

/* remote tileserver */
// const ctsProtocol = 'https://';
// const clereTileserverSubdomain = 'cts.dev.';
// const clereTileserverDomain = 'cleregolfhub.com';
// const clereTileserverPort = '';

/* prod tileserver */
const ctsProtocol = 'https://';
const clereTileserverSubdomain = 'cts.';
const clereTileserverDomain = 'cleregolfhub.com';
const clereTileserverPort = '';

/* local tileserver 
const ctsProtocol = 'http://';
const clereTileserverSubdomain = '';
const clereTileserverDomain = 'localhost';
const clereTileserverPort = ':9000'; */

const apiUrl = apiProtocol + '://' + apiSubdomain + url + ':' + apiPort;
const ctsurl =
    ctsProtocol +
    clereTileserverSubdomain +
    clereTileserverDomain +
    clereTileserverPort;

export default createStore({
    state: {
        apiUrl: apiUrl,
        ctsUrl: ctsurl,
        authToken: localStorage.getItem('authToken')
            ? JSON.parse(localStorage.getItem('authToken') as string)
            : null,
        accountInfo: localStorage.getItem('accountInfo')
            ? JSON.parse(localStorage.getItem('accountInfo') as string)
            : null,
        cancelToken: axios.CancelToken.source(),
        isMobile: false,
        unauthenticatedRoutes: ['/'],
        versionString: 'live-0.0.6',
    },
    mutations: {
        setAuthToken(state: any, authToken: Token) {
            localStorage.setItem('authToken', JSON.stringify(authToken));
            state.authToken = authToken;
        },
        removeAuthToken(state: any) {
            localStorage.removeItem('authToken');
            state.authToken = null;
        },
        setAccountInfo(state: any, accountInfo: any) {
            localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
            state.accountInfo = accountInfo;
        },
        removeAccountInfo(state: any) {
            localStorage.removeItem('accountInfo');
            state.account = null;
        },
    },
    actions: {},
    modules: {},
    getters: {
        isLoggedIn: (state: any) => {
            return !!state.authToken;
        },
    },
});
