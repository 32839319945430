<template>
    <div class="sub-navbar">
        <div class="sub-links" v-if="currentNavbarLink">
            <div
                class="sub-link"
                :class="{ 'sub-link-selected': $route.path === subLink.path }"
                v-for="(subLink, i) in filteredSubLinks"
                :key="i"
                @click="routeTo(subLink)"
            >
                {{ subLink.name }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// models
import { NavbarLink } from '@/components/navbar.vue';

export default defineComponent({
    props: ['mainLinks'],
    setup(props: any) {
        // hooks
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        // computed
        const currentNavbarLink = computed(() => {
            return props.mainLinks.find(
                (x: any) => x.path === `/${route.path.split('/')[1]}`,
            );
        });

        const filteredSubLinks = computed(() => {
            if (!currentNavbarLink.value.subLinks) {
                return null;
            }

            return currentNavbarLink.value.subLinks.filter((x: NavbarLink) => {
                if (x.permission) {
                    return (
                        store.state.accountInfo.permissions.indexOf(
                            x.permission,
                        ) !== -1
                    );
                } else {
                    return true;
                }
            });
        });

        // methods
        const routeTo = (navbarLink: NavbarLink) => {
            router.push({ path: navbarLink.path });
        };

        return {
            currentNavbarLink,
            routeTo,
            filteredSubLinks,
        };
    },
});
</script>

<style scoped>
.sub-navbar {
    background: #e0e0e0;
    border-bottom: 1px solid #ccc;
    padding: 0 30px;
}

.sub-links {
    display: flex;
    position: relative;
    z-index: 1;
    top: 1px;
    font-size: 0.9rem;
}

.sub-link {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    transition-duration: 0.1s;
    user-select: none;
}

.sub-link:hover {
    border-bottom: 2px solid #bbb;
    transition-duration: 0.1s;
}

.sub-link-selected {
    cursor: default;
    border-bottom: 2px solid var(--color-secondary) !important;
}
</style>
