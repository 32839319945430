<template>
    <div>
        <div class="documentation-header">Vector Layer processing</div>
        <vm-card class="mb-4">
            <vm-card-header>QGIS - Connect to the database</vm-card-header>
            <vm-card-content>
                <div class="mb-2">
                    <ol class="documentation-list">
                        <li>
                            Right click PostGIS on left hand side
                        </li>
                        <li>
                            Click "New Connection..."
                        </li>
                        <li>
                            Name: Something descriptive (e.g.
                            clere-golf-hub-dev) Host: dev.cleregolfhub.com Port:
                            25432 Database: clere
                        </li>
                        <li>
                            Under authentication click the "Basic" tab, ensure
                            you check the "Store" checkbox to save the
                            credentials.
                        </li>
                        <li>
                            User name: jamie Password: Cl3r3G0lf
                        </li>
                        <li>
                            Click "Test Connection", you should see a message
                            saying "Connect to 'name' was successful".
                        </li>
                        <li>
                            Click OK
                        </li>
                    </ol>
                </div>
            </vm-card-content>
        </vm-card>
        <vm-card class="mb-4">
            <vm-card-header>Babylon - Create DTM</vm-card-header>
            <vm-card-content>
                <div>
                    <div class="mb-4">
                        Drag the crop layer for the required course from the
                        postgis connection to copy it to the QGIS legend.
                    </div>
                    <div class="mb-4">
                        Ensure the crop layer has a course sized geometry. If a
                        course sized geometry does not exist then draw one using
                        the 'Add Polygon Feature' tool after you have enabled
                        editing with the 'Toggle Editing' button.
                    </div>
                    <div class="mb-4">
                        Search in the Processing Toolbox (usually on the right
                        side of QGIS) for the Buffer tool and buffer only the
                        large crop by 100m
                    </div>
                    <div class="mb-4">
                        Search in the Processing Toolbox for the Bounding Box
                        tool and run it on the buffer layer
                    </div>
                    <div class="mb-4">
                        Export the bounding box as geojson called
                        <code class="code-block"
                            >'course_schema'_course_extent.geojson</code
                        >
                        Note, it is important that you name this file correctly
                        in the first instance as it cannot be editted while
                        preserving it's dependent data after the original export
                    </div>
                    <div class="mb-4">
                        Move the geojson file and the DTM into:
                        <code class="mb-2"
                            ><pre>~/git/babylon-dtm-tool/data</pre></code
                        >
                    </div>
                    <div class="mb-4">
                        SSH into the
                        <code class="code-block">apollo</code> server, modify
                        the
                        <code class="code-block">cut-entire.sh</code>
                        <code class="code-block">COURSE_NAME</code> to the
                        schema you want to process. If you are comfortable using
                        VIM then you can use
                        <code class="mb-2"><pre>vim cut-entire.sh</pre></code>
                        to edit the code, otherwise use
                        <code class="mb-2"><pre>nano cut-entire.sh</pre></code>
                    </div>
                    <div class="mt-2 mb-4">
                        Now that the course name is correct, run
                        <code class="mb-2"><pre>./cut-entire.sh</pre></code>
                    </div>
                    <div class="mt-2 mb-4">
                        Modify the
                        <code class="code-block">create-dtm-png.py</code>
                        courseName to the schema you want to process.
                    </div>
                    <div class="mb-4">
                        Now that the course name is correct, run
                        <code class="mb-2"><pre>./create-dtm-png.py</pre></code>
                    </div>
                    <div class="mb-4">
                        Move the resultant
                        <code class="code-block"
                            >'course_schema'_elevation.png</code
                        >
                        in the output directory into
                        <code
                            ><pre>
api_volumes/babylon/dtm/'course_schema'_dtm.png</pre
                            ></code
                        >
                    </div>
                </div>
            </vm-card-content>
        </vm-card>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    setup() {
        return {};
    },
});
</script>

<style scoped></style>
