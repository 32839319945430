<template>
    <form class="form" @submit.prevent="saveCallback">
        <input
            autocomplete="off"
            name="hidden"
            type="text"
            style="display:none;"
        />
        <div class="account-form">
            <vm-text-field
                variant="outlined"
                label="First name"
                v-model="props.account.firstName"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Last name"
                v-model="props.account.lastName"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Email"
                v-model="props.account.email"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Password"
                type="password"
                v-model="props.account.password"
            />

            <vm-dropdown
                class="mt-4"
                variant="outlined"
                label="Role"
                title="name"
                :options="roles"
                v-model="props.account.roleId"
            />
        </div>

        <div class="row-reverse mt-4">
            <vm-button
                type="submit"
                :elevation="null"
                primaryColor="var(--triadic2-600)"
            >
                SAVE
            </vm-button>
            <vm-button
                class="mr-2"
                variant="text"
                primaryColor="var(--triadic2-600)"
                @click="closeCallback"
            >
                CANCEL
            </vm-button>
        </div>
    </form>
</template>

<script lang="ts">
// vue
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';

// services
import { useRoleService } from '@/api/service/role.service';

export default defineComponent({
    props: ['account', 'saveCallback', 'closeCallback'],
    setup(props: any) {
        // hookes
        const store = useStore();

        // services
        const { roles, getRoleAll } = useRoleService();

        // lifecycle hooks
        onMounted(async () => {
            if (
                store.state.accountInfo.permissions.indexOf('canReadRole') !==
                -1
            ) {
                getRoleAll();
            }
        });

        return {
            props,
            roles,
        };
    },
});
</script>

<style scoped>
.account-form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.button-container {
    display: flex;
    justify-content: right;
}
</style>
