<template>
    <form @submit.prevent="saveCallback">
        <input
            autocomplete="off"
            name="hidden"
            type="text"
            style="display:none;"
        />
        <div>
            <vm-text-field
                variant="outlined"
                label="Environment name"
                v-model="props.environment.name"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Tag"
                v-model="props.environment.tag"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Front-end URL"
                v-model="props.environment.frontEndUrl"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Database host"
                v-model="props.environment.dbHost"
            />

            <vm-text-field
                class="mt-4"
                type="number"
                variant="outlined"
                label="Database port"
                v-model="props.environment.dbPort"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Database name"
                v-model="props.environment.dbDatabaseName"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Database user"
                v-model="props.environment.dbUser"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Database password"
                v-model="props.environment.dbPassword"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Geoserver database host"
                v-model="props.environment.geoserverDbHost"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Geoserver URL"
                v-model="props.environment.geoserverUrl"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Geoserver username"
                v-model="props.environment.geoserverName"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Geoserver password"
                v-model="props.environment.geoserverPassword"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="Geoserver volume path"
                v-model="props.environment.geoserverVolumePath"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="SSH host"
                v-model="props.environment.sshHost"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="SSH user"
                v-model="props.environment.sshUser"
            />

            <vm-text-field
                class="mt-4"
                type="number"
                variant="outlined"
                label="SSH port"
                v-model="props.environment.sshPort"
            />

            <vm-text-field
                class="mt-4"
                variant="outlined"
                label="SSH key name"
                v-model="props.environment.sshKeyName"
            />

            <vm-switch
                class="mt-4"
                label="Protected environment"
                v-model="props.environment.isProtected"
            />
        </div>

        <div class="row jc-flex-end">
            <vm-button
                class="mr-2"
                variant="text"
                primaryColor="var(--triadic2-600)"
                @click="closeCallback"
            >
                CANCEL
            </vm-button>
            <vm-button
                type="submit"
                :elevation="null"
                primaryColor="var(--triadic2-600)"
            >
                SAVE
            </vm-button>
        </div>
    </form>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['environment', 'saveCallback', 'closeCallback', 'isEditing'],
    setup(props: any) {
        return {
            props,
        };
    },
});
</script>

<style scoped></style>
