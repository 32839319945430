<template>
    <div class="navbar">
        <div class="navbar-container">
            <div class="left">
                <div class="brand">
                    <img
                        class="logo"
                        alt="clerenet logo"
                        src="../assets/svg/clere-target.svg"
                    />
                    <div class="text-clere">clere</div>
                    <div class="text-net">net</div>
                </div>
                <div class="links">
                    <div
                        class="link"
                        :class="{
                            'link-selected':
                                route.path.indexOf(link.path) === 0,
                        }"
                        v-for="(link, i) in filteredNavbarLinks"
                        :key="i"
                        @click="routeTo(link)"
                    >
                        {{ link.name }}
                    </div>
                </div>
            </div>
            <div class="right">
                <account-menu></account-menu>
            </div>
        </div>
        <sub-navbar v-if="isSubNavbar" :mainLinks="mainLinks"> </sub-navbar>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// components
import AccountMenu from '@/components/account-menu.vue';
import SubNavbar from '@/components/sub-navbar.vue';

// classes
export class NavbarLink {
    public name!: string;
    public path!: string;
    public permission?: string;
    public subLinks?: NavbarLink[];

    constructor() {
        this.name = '';
    }
}

export default defineComponent({
    components: {
        AccountMenu,
        SubNavbar,
    },
    setup() {
        // hooks
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        // properties
        const mainLinks: NavbarLink[] = [
            {
                name: 'Data manager',
                path: '/data-manager',
                subLinks: [
                    {
                        name: 'Data migration',
                        path: '/data-manager',
                    },
                    {
                        name: 'Migration requests',
                        path: '/data-manager/migration-requests',
                    },
                    {
                        name: 'Upload raster',
                        path: '/data-manager/upload-raster',
                    },
                    {
                        name: 'Config File',
                        path: '/data-manager/download-config',
                    },
                ],
            },
            {
                name: 'Issue tracker',
                path: '/issue-tracker',
            },
            {
                name: 'Documentation',
                path: '/documentation',
                subLinks: [
                    {
                        name: 'Overview',
                        path: '/documentation',
                        permission: 'canReadDocumentation',
                    },
                    {
                        name: 'Satellite Imagery',
                        path: '/documentation/mosaic',
                        permission: 'canReadDocumentation',
                    },
                    {
                        name: 'DEM',
                        path: '/documentation/dem',
                        permission: 'canReadDocumentation',
                    },
                    {
                        name: 'Vectors',
                        path: '/documentation/vector',
                        permission: 'canReadDocumentation',
                    },
                ],
            },
            {
                name: 'Admin',
                path: '/admin',
                subLinks: [
                    {
                        name: 'Accounts',
                        path: '/admin/accounts',
                        permission: 'canReadAccount',
                    },
                    {
                        name: 'Roles',
                        path: '/admin/roles',
                        permission: 'canReadRole',
                    },
                    {
                        name: 'Environments',
                        path: '/admin/environments',
                        permission: 'canReadEnvironment',
                    },
                ],
            },
            {
                name: 'Hub',
                path: '/hub',
                subLinks: [
                    {
                        name: 'Course schema',
                        path: '/hub/course-schema',
                        permission: '',
                    },
                    {
                        name: 'Mapbox',
                        path: '/hub/mapbox',
                        permission: '',
                    },
                    {
                        name: 'Tileserver Configs',
                        path: '/hub/tileserver-config',
                        permission: '',
                    },
                ],
            },
        ];

        // computed
        const filteredNavbarLinks = computed(() => {
            return mainLinks.filter((x: NavbarLink) => {
                if (x.subLinks) {
                    return x.subLinks.find((y: NavbarLink) => {
                        if (y.permission) {
                            return (
                                store.state.accountInfo.permissions.indexOf(
                                    y.permission,
                                ) !== -1
                            );
                        } else {
                            return true;
                        }
                    });
                } else {
                    if (x.permission) {
                        return (
                            store.state.accountInfo.permissions.indexOf(
                                x.permission,
                            ) !== -1
                        );
                    } else {
                        return true;
                    }
                }
            });
        });

        // methods
        const routeTo = (navbarLink: NavbarLink) => {
            router.push({ path: navbarLink.path });
        };

        const isSubNavbar = () => {
            const mainLink = mainLinks.find(
                (x) => route.path.indexOf(x.path) === 0,
            );
            return mainLink && mainLink.subLinks;
        };

        return {
            router,
            route,
            mainLinks,
            filteredNavbarLinks,
            routeTo,
            isSubNavbar,
        };
    },
});
</script>

<style scoped>
.navbar {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 30px;
    z-index: 5;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background: #333;
    color: #fff;
    padding: 0 30px;
}

.left,
.right {
    display: flex;
    align-items: center;
}

.brand {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.4rem;
    user-select: none;
}

.brand .logo {
    object-fit: scale-down;
    --size: 30px;
    width: var(--size);
    height: var(--size);
    margin-right: 5px;
}

.brand .text-clere,
.brand .text-net {
    display: inline-flex;
    align-items: center;
}

.brand .text-net {
    color: var(--color-primary);
}

.links {
    height: 100%;
    font-size: 0.9rem;
    user-select: none;
    margin-left: 30px;
}

.link {
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    height: 100%;
    cursor: pointer;
    transition-duration: 0.1s;
}

.link:hover {
    background: rgba(255, 255, 255, 0.1);
    transition-duration: 0.1s;
}

.link-selected {
    background: none !important;
    color: #888;
    cursor: default;
    pointer-events: none;
}
</style>
