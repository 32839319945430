<template>
    <vm-container>
        <clere-title>
            Upload raster
        </clere-title>
        <vm-card>
            <vm-card-content>
                <div class="upload">
                    <div class="upload-icon mb-2">
                        <i class="far fa-cloud-upload-alt"></i>
                    </div>
                    <input
                        ref="fileUpload"
                        type="file"
                        style="display: none"
                        @change="handleFileUpload"
                        accept=".tif, .tiff"
                        multiple
                    />
                    <vm-button
                        class="mb-2"
                        primaryColor="var(--color-primary)"
                        @click="chooseFiles"
                    >
                        CHOOSE FILES TO UPLOAD
                    </vm-button>
                    <div>
                        or drag and drop them here
                    </div>
                </div>
                <div v-for="(file, i) in files" :key="i">
                    <upload-chip class="mt-4" :clearFile="clearFile" :index="i">
                        {{ file.name }}
                    </upload-chip>
                </div>
            </vm-card-content>
        </vm-card>
    </vm-container>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted } from 'vue';

// components
import ClereTitle from '@/components/clere-title.vue';
import UploadChip from '@/components/upload-chip.vue';

export default defineComponent({
    components: {
        ClereTitle,
        UploadChip,
    },
    setup() {
        // refs
        const fileUpload = ref<null | HTMLInputElement>(null);

        // properties
        const isLoading = ref(true);
        const files = ref<File[]>([]);

        // methods
        const chooseFiles = () => {
            if (fileUpload.value) {
                fileUpload.value.click();
            }
        };

        const handleFileUpload = () => {
            if (fileUpload.value && fileUpload.value.files) {
                const uploadFiles = fileUpload.value.files;
                const newFiles: any = [];
                for (let i = 0; i < uploadFiles.length; i++) {
                    newFiles.push(uploadFiles[i]);
                }
                newFiles.forEach((file: File) => {
                    files.value.push(file);
                });
            }
        };

        const clearFile = (i: number) => {
            files.value.splice(i, 1);
        };

        // lifecycle hooks
        onMounted(async () => {
            isLoading.value = false;
        });

        return {
            fileUpload,
            isLoading,
            chooseFiles,
            handleFileUpload,
            files,
            clearFile,
        };
    },
});
</script>

<style scoped>
.upload {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 3px dashed rgba(0, 0, 0, 0.2);
    padding: 120px 0;
}

.upload-icon {
    font-size: 4.5rem;
    color: var(--color-primary);
}

.upload-text {
    font-size: 2rem;
}
</style>
