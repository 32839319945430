<template>
    <div class="confirm-container">
        <div class="lg-text center-text">
            <slot></slot>
        </div>
        <div class="row jc-flex-end mt-4">
            <vm-button
                :primaryColor="primaryColor"
                variant="text"
                :elevation="null"
                @click="closeModal"
            >
                CANCEL
            </vm-button>
            <vm-button
                class="ml-2"
                :primaryColor="primaryColor"
                :elevation="null"
                @click="confirm(payload)"
            >
                CONFIRM
            </vm-button>
        </div>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['closeModal', 'confirm', 'payload', 'primaryColor'],
    setup(props: any) {
        return {
            props,
        };
    },
});
</script>

<style scoped>
.confirm-container {
    display: flex;
    flex-direction: column;
}
</style>
