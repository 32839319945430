<template>
    <vm-modal ref="dataMigrationModal" :isPreventClose="isPushLoading">
        <vm-card-header>
            Data migration
        </vm-card-header>
        <vm-card-content>
            <push-courses
                :environments="environments"
                :selectedCourseIds="selectedCourseIds"
                :courseSchemas="courseSchemas"
                :closeModal="closeModal"
                :environment="props.environment"
                :pushStartedCallback="pushStartedCallback"
                :pushFinishedCallback="pushFinishedCallback"
            />
        </vm-card-content>
    </vm-modal>
    <div>
        <div class="card-topper">
            <div class="row">
                <div
                    :class="
                        environment.isProtected
                            ? 'environment-protected'
                            : 'environment-not-protected'
                    "
                >
                    <i
                        class="fal fa-lock-open"
                        v-if="!environment.isProtected"
                    ></i>
                    <i class="fal fa-lock" v-if="environment.isProtected"></i>
                </div>
                <div>
                    <div class="environment-title mb-1">
                        {{ environment.name }}
                    </div>
                    <div class="environment-tag">
                        {{ environment.tag }}
                    </div>
                </div>
            </div>
            <div>
                <a
                    class="btn btn-link"
                    :href="environment.frontEndUrl"
                    target="_blank"
                >
                    {{ environment.frontEndUrl }}
                </a>
            </div>
        </div>
        <dropdown title="Additional information">
            <div class="row jc-center" v-if="isLoading">
                <vm-loading-spinner />
            </div>
            <div v-if="!isLoading">
                <div class="info mb-4" v-if="!isLoading">
                    <div class="table-container">
                        <div class="info-table-title">Database</div>
                        <table class="info-table">
                            <tr>
                                <td class="td-left">Host</td>
                                <td>{{ environment.dbHost }}</td>
                            </tr>
                            <tr>
                                <td class="td-left">Port</td>
                                <td>{{ environment.dbPort }}</td>
                            </tr>
                            <tr>
                                <td class="td-left">User</td>
                                <td>{{ environment.dbUser }}</td>
                            </tr>
                            <tr>
                                <td class="td-left">Password</td>
                                <td>{{ environment.dbPassword }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="table-container">
                        <div class="info-table-title">Geoserver</div>
                        <table class="info-table">
                            <tr>
                                <td class="td-left">URL</td>
                                <td>
                                    <a
                                        :href="environment.geoserverUrl"
                                        target="_blank"
                                    >
                                        {{ environment.geoserverUrl }}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="td-left">Username</td>
                                <td>{{ environment.geoserverName }}</td>
                            </tr>
                            <tr>
                                <td class="td-left">Password</td>
                                <td>{{ environment.geoserverPassword }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </dropdown>
        <dropdown class="mt-2" title="Data Migration">
            <div style="border: 1px solid rgba(0,0,0,0.1); border-radius: 5px;">
                <vm-grid
                    ref="courseSchemaGrid"
                    :config="courseSchemaConfig"
                    :rowData="courseSchemas"
                    :isSelectable="true"
                    :courseSchemaSelect="courseSchemaSelect"
                    :isGridBorder="true"
                    :isLoading="isLoading"
                    :maxRows="10"
                />
            </div>
            <div v-if="!isLoading">
                <div class="card-footer">
                    <div class="button-container">
                        <vm-button
                            primaryColor="var(--triadic2-600)"
                            :elevation="null"
                            :class="{ disabled: coursesSelectedCount === 0 }"
                            @click="ClearSelection"
                        >
                            Clear Selection
                        </vm-button>
                        <vm-button
                            primaryColor="var(--triadic2-600)"
                            :elevation="null"
                            :class="{
                                disabled:
                                    coursesSelectedCount === 0 ||
                                    coursesSelectedCount > 1,
                            }"
                            @click="ClearCache"
                        >
                            Clear Cache
                        </vm-button>
                        <vm-button
                            primaryColor="var(--triadic2-600)"
                            :elevation="null"
                            :class="{
                                disabled:
                                    coursesSelectedCount === 0 ||
                                    coursesSelectedCount > 1,
                            }"
                            @click="DeleteGreenData"
                        >
                            Clear Green Data
                        </vm-button>
                    </div>
                    <div>Courses Selected: {{ coursesSelectedCount }}</div>
                    <!-- <vm-button
                        primaryColor="var(--triadic2-600)"
                        :elevation="null"
                        :class="{
                            disabled:
                                coursesSelectedCount === 0 ||
                                coursesSelectedCount > 1,
                        }"
                        @click="UpdateCache"
                    >
                        Update Cache
                    </vm-button> -->
                    <vm-button
                        primaryColor="var(--triadic2-600)"
                        :elevation="null"
                        :class="{ disabled: coursesSelectedCount === 0 }"
                        @click="openModal"
                    >
                        PUSH {{ coursesSelectedCount > 1 ? ' MULTIPLE' : '' }}
                    </vm-button>
                </div>
            </div>
        </dropdown>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useToast } from 'vue-toastification';

// vue3-material
import { VmModal } from 'vue3-material';

// components
import PushCourses from './push-courses.vue';
import Dropdown from '@/components/dropdown.vue';

// services
import { useCourseSchemaService } from '@/api/service/course-schema.service';

// configs
import { courseSchemaConfig } from '@/config/course-schema-config';

export default defineComponent({
    components: {
        PushCourses,
        Dropdown,
    },
    props: ['environment', 'environments'],
    setup(props: any) {
        // hooks
        const toast = useToast();

        // services
        const {
            courseSchemas,
            getCourseSchema,
            updateCache,
            clearCache,
            deleteGreenData,
        } = useCourseSchemaService();

        // refs
        const courseSchemaGrid = ref(null);
        const dataMigrationModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const courseSchemaSelect = ref<any[]>([]);
        const selectedCourseIds = ref<number[]>([]);
        const isLoading = ref(true);
        const isPushLoading = ref(false);

        // computed
        const coursesSelectedCount = computed(() => {
            return (courseSchemaGrid.value as any).getSelectedIds().length;
        });

        // methods
        const openModal = () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();
            dataMigrationModal.value.show();
        };

        const closeModal = () => {
            dataMigrationModal.value.hide();
        };

        const pushStartedCallback = () => {
            isPushLoading.value = true;
        };

        const pushFinishedCallback = () => {
            isPushLoading.value = false;
            (courseSchemaGrid.value as any).deselectAll();
            setTimeout(() => {
                closeModal();
            });
        };
        const ClearSelection = () => {
            selectedCourseIds.value = [];
            if (courseSchemaGrid.value) {
                (courseSchemaGrid.value as any).deselectAll();
            }
        };

        const ClearCache = async () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();

            try {
                await clearCache(selectedCourseIds.value[0]);
                toast.success('Successfully cleared Cache');
            } catch (e) {
                toast.error('Failed to clear Cache');
            }
        };

        const DeleteGreenData = async () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();
            const environmentId = props.environment.id;

            try {
                await deleteGreenData(
                    selectedCourseIds.value[0],
                    environmentId,
                );
                toast.success('Successfully cleared Green Data');
            } catch (e) {
                toast.error('Failed to clear Green Data');
            }
        };

        const UpdateCache = async () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();

            try {
                await updateCache(selectedCourseIds.value[0]);
                toast.success('Successfully migrated data');
            } catch (e) {
                toast.error('Failed to update Cache');
            }
        };

        // lifecycle hooks
        onMounted(async () => {
            await getCourseSchema(props.environment.id);
            isLoading.value = false;
        });

        return {
            props,
            courseSchemas,
            courseSchemaGrid,
            courseSchemaSelect,
            coursesSelectedCount,
            courseSchemaConfig,
            selectedCourseIds,
            dataMigrationModal,
            openModal,
            closeModal,
            pushStartedCallback,
            pushFinishedCallback,
            isLoading,
            isPushLoading,
            ClearCache,
            UpdateCache,
            DeleteGreenData,
            ClearSelection,
        };
    },
});
</script>

<style scoped>
.card-topper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-border);
}

.info {
    display: flex;
    justify-content: space-evenly;
}

.table-container {
    width: 40%;
}

.table-container h4 {
    margin-bottom: 10px;
}

.info-table-title {
    font-weight: 500;
    padding-bottom: 10px;
}

.info-table {
    width: 100%;
    border-collapse: collapse;
}

.info-table td.td-left {
    width: 25%;
    padding-right: 20px;
}

.info-title {
    display: inline-block;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    font-size: 0.9rem;
}

/* .card-footer :nth-child(3) {
    order: -1;
} */
.button-container {
    display: flex;
    gap: 10px;
}

.environment-protected,
.environment-not-protected {
    --width: 40px;
    --height: 40px;
    width: var(--width);
    height: var(--height);
    min-width: var(--width);
    min-height: var(--height);
    max-width: var(--width);
    max-height: var(--height);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-right: 15px;
}

.environment-protected {
    background: rgba(255, 0, 0, 0.15);
    border: 1px solid rgb(255, 0, 0);
    color: rgb(255, 0, 0);
}

.environment-not-protected {
    background: rgba(0, 255, 0, 0.15);
    border: 1px solid rgb(0, 180, 0);
    color: rgb(0, 180, 0);
}

.environment-title {
    font-size: 1.4rem;
    font-weight: 400;
}

.environment-tag {
    font-size: 1rem;
    color: #888;
}
</style>
