<template>
    <vm-container>
        <clere-title>Settings</clere-title>
        <vm-card>
            <vm-card-content>
                <div class="row-center" v-if="isLoading">
                    <vm-loading-spinner :scale="0.5" />
                </div>
                <table class="version" v-if="!isLoading">
                    <tr>
                        <td>Front-end version:</td>
                        <td>{{ store.state.versionString }}</td>
                    </tr>
                    <tr>
                        <td>API version:</td>
                        <td>{{ apiVersion }}</td>
                    </tr>
                </table>
            </vm-card-content>
        </vm-card>
    </vm-container>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

// components
import ClereTitle from '@/components/clere-title.vue';

// services
import { useAuthService } from '@/api/service/auth.service';

export default defineComponent({
    components: {
        ClereTitle,
    },
    setup() {
        // hooks
        const store = useStore();

        // services
        const { getVersion } = useAuthService();

        // properties
        const apiVersion = ref('');
        const isLoading = ref(true); // Explicitly type as Ref<boolean>

        // lifecycle hooks
        onMounted(async () => {
            const res = await getVersion();
            apiVersion.value = res.data.tag;
            isLoading.value = false;
        });

        return {
            store,
            apiVersion,
            isLoading,
        };
    },
});
</script>

<style scoped>
table.version {
    border-spacing: 0;
    border-collapse: collapse;
}

table.version > tr > td {
    padding: 5px 10px;
}

table.version > tr > td:first-child {
    padding-left: 0;
}

table.version > tr > td:last-child {
    padding-right: 0;
}
</style>
