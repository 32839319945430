<template>
    <div class="login">
        <div class="login-brand-container">
            <div class="login-brand">
                <img :src="clerenetLogo" />
            </div>
            <div class="welcome-message">
                Welcome to clere<span style="color: var(--color-primary)"
                    >net</span
                >
            </div>
        </div>
        <vm-card class="login-pane">
            <vm-card-content>
                <form @submit.prevent="login">
                    <vm-text-field
                        label="Email"
                        v-model="loginCredentials.email"
                    />

                    <vm-text-field
                        class="mt-4"
                        label="Password"
                        type="password"
                        v-model="loginCredentials.password"
                    />

                    <div class="buttons-container mt-4">
                        <vm-button
                            primaryColor="var(--triadic2-600)"
                            :elevation="null"
                            type="submit"
                        >
                            LOGIN
                        </vm-button>
                    </div>
                </form>
            </vm-card-content>
        </vm-card>
        <img class="login-svg-left" :src="loginLeft" />
        <img class="login-svg-right" :src="loginRight" />
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

// assets
import clerenetLogo from '@/assets/clerenet-logo.png';
import loginLeft from '@/assets/svg/login-left.svg';
import loginRight from '@/assets/svg/login-right.svg';

// services
import { useAuthService } from '@/api/service/auth.service';

// models
import { LoginCredentials } from '@/api/model/login.model';

export default defineComponent({
    setup() {
        // hooks
        const router = useRouter();

        // services
        const { logIn } = useAuthService();

        // properties
        const loginCredentials = ref(new LoginCredentials());

        // methods
        const login = async () => {
            await logIn(loginCredentials.value);
            router.push({ path: '/data-manager' });
        };

        return {
            clerenetLogo,
            loginLeft,
            loginRight,
            login,
            loginCredentials,
        };
    },
});
</script>

<style scoped>
.login {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    background: #fcfcfc;
}

.login-svg-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.login-svg-right {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.login-brand-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 80px 30px;
    margin-bottom: 60px;
    z-index: 1;
}

.login-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: 80px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    margin-bottom: 30px;
    background: #fff;
    box-shadow: 0 5px 8px 0 var(--color-box-shadow);
}

.welcome-message {
    font-size: 1.8rem;
    text-align: center;
}

.login-brand img {
    --size: 50px;
    width: var(--size);
    height: var(--size);
    object-fit: scale-down;
}

.login-pane {
    width: 400px;
    background: #fff;
    z-index: 1;
}

.text-input-wrapper {
    display: flex;
    flex-direction: column;
}

.login-label {
    font-size: 0.9rem;
}

.login-input {
    outline: none;
    padding: 10px;
    border: 1px solid var(--color-border);
    border-bottom: 2px solid var(--color-border);
    border-radius: 3px;
    transition-duration: 0.1s;
}

.login-input:focus {
    border-bottom: 2px solid var(--color-primary);
    transition-duration: 0.1s;
}

.buttons-container {
    display: flex;
    justify-content: center;
}
</style>
