<template>
    <div>
        <div class="documentation-header">DEM processing</div>
        <vm-card>
            <vm-card-content>
                <div class="documentation-sub-header" id="step1">
                    Mapbox - Convert DEM into mapbox tiles format
                </div>
                <div class="documentation-content">
                    <ol class="documentation-list">
                        <li>
                            Navigate to the root directory of the geotools
                            repository.
                        </li>
                        <li>
                            Move the DEM TIFF into the data/ directory.
                        </li>
                        <li>
                            Process the TIFF using the
                            convert-satellite-mapbox.sh script.
                        </li>
                    </ol>
                </div>
            </vm-card-content>
        </vm-card>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    setup() {
        return {};
    },
});
</script>

<style scoped></style>
