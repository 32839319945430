<template>
    <vm-container>
        <clere-title>
            Environments
        </clere-title>
        <div class="mb-3">
            <vm-button
                primaryColor="var(--triadic2-600)"
                @click="addEnvironment()"
            >
                ADD ENVIRONMENT
            </vm-button>
        </div>

        <vm-card>
            <vm-grid
                :config="gridConfig"
                :rowData="environments"
                :editCallback="editEnvironment"
                :deleteCallback="deleteEnvironment"
                :isLoading="isLoading"
            >
            </vm-grid>
        </vm-card>
    </vm-container>

    <vm-modal ref="environmentEditorModal">
        <vm-card-header>
            Environment editor
        </vm-card-header>
        <vm-card-content>
            <environment-editor
                :environment="environment"
                :saveCallback="saveEnvironment"
                :closeCallback="hideModal"
                :isEditing="isEditing"
            >
            </environment-editor>
        </vm-card-content>
    </vm-modal>

    <vm-modal ref="confirmDeleteModal">
        <vm-card-header>Delete environment</vm-card-header>

        <vm-card-content>
            <confirm
                :closeModal="hideDeleteModal"
                :confirm="deleteSelectedEnvironment"
                primaryColor="var(--red-100)"
            >
                Are you sure you want to delete environment
                {{ environment.name }}?
            </confirm>
        </vm-card-content>
    </vm-modal>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted } from 'vue';

// vue3-material
import { VmModal } from 'vue3-material';

// configs
import { environmentConfig } from '@/config/environment-config';

// components
import EnvironmentEditor from './environment-editor.vue';
import ClereTitle from '@/components/clere-title.vue';
import Confirm from '@/components/confirm.vue';
import { CrActions } from '@/components/grid-plugins';

// services
import { useEnvironmentService } from '@/api/service/environment.service';

// models
import { Environment } from '@/api/model/environment.model';

export default defineComponent({
    components: {
        EnvironmentEditor,
        ClereTitle,
        Confirm,
    },
    setup() {
        // services
        const {
            environments,
            getEnvironment,
            getEnvironmentAll,
            createEnvironment,
            updateEnvironment,
            deleteEnvironment,
        } = useEnvironmentService();

        // refs
        const environmentEditorModal = ref<InstanceType<typeof VmModal>>();
        const confirmDeleteModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const modalTitle = 'Add Environment';
        const isEditing = ref(false);
        const environment = ref(new Environment());
        const isLoading = ref(true);

        // methods
        const openModal = () => {
            environmentEditorModal.value.show();
        };

        const hideModal = () => {
            environmentEditorModal.value.hide();
        };

        const hideDeleteModal = () => {
            confirmDeleteModal.value.hide();
        };

        const saveEnvironment = async () => {
            if (environment.value.dbPort) {
                environment.value.dbPort = Number(environment.value.dbPort);
            }
            if (isEditing.value) {
                await updateEnvironment(environment.value);
            } else {
                await createEnvironment(environment.value);
            }
            environmentEditorModal.value.hide();
            isEditing.value = false;
        };

        const editEnvironment = (e: Environment) => {
            const environmentId = e.id;
            const acc = environments.value.find((x) => x.id === environmentId);
            if (acc) {
                environment.value = { ...acc };
            }
            isEditing.value = true;
            openModal();
        };

        const addEnvironment = () => {
            environment.value = new Environment();
            isEditing.value = false;
            openModal();
        };

        const showEnvironmentDelete = async (e: Environment) => {
            if (e && e.id) {
                const existingEnvironment = await getEnvironment(e.id);
                environment.value = existingEnvironment;
                confirmDeleteModal.value.show();
            }
        };

        const deleteSelectedEnvironment = async () => {
            if (environment.value.id) {
                await deleteEnvironment(environment.value.id);
                hideDeleteModal();
            }
        };

        // lifecycle hooks
        onMounted(async () => {
            await getEnvironmentAll();
            isLoading.value = false;
        });

        // configs
        const gridConfig = [
            ...environmentConfig,
            {
                title: 'Actions',
                fitWidth: true,
                cellRenderer: {
                    component: CrActions,
                    exported: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                callback: editEnvironment,
                            },
                            {
                                icon: 'fal fa-trash-alt',
                                callback: showEnvironmentDelete,
                            },
                        ],
                    },
                },
            },
        ];

        return {
            modalTitle,
            isEditing,
            gridConfig,
            saveEnvironment,
            editEnvironment,
            addEnvironment,
            deleteEnvironment,
            environment,
            environments,
            environmentEditorModal,
            confirmDeleteModal,
            openModal,
            hideModal,
            hideDeleteModal,
            deleteSelectedEnvironment,
            isLoading,
        };
    },
});
</script>

<style scoped></style>
