<template>
    <div>
        <vm-text-field
            class="mt-4"
            variant="outlined"
            label="Course Schema ID"
            v-model.number="props.courseMapboxLayer.CourseSchemaid"
            type="number"
        />
        <vm-text-field
            class="mt-4 mb-4"
            variant="outlined"
            label="Layer Table Name"
            v-model="props.courseMapboxLayer.LayerTableName"
        />
        <vm-text-field
            class="mt-4 mb-4"
            variant="outlined"
            label="Style"
            v-model="props.courseMapboxLayer.style"
        />
        <vm-text-field
            class="mt-4 mb-4"
            variant="outlined"
            label="Columns"
            v-model="props.courseMapboxLayer.columns"
        />
        <vm-text-field
            class="mt-4 mb-4"
            variant="outlined"
            label="Layer Category Id"
            placeholder=" "
            v-model.number="props.courseMapboxLayer.LayerCategoryId"
            type="number"
        />
        <vm-switch
            class="mt-4 mb-4"
            label="Is Default"
            v-model="props.courseMapboxLayer.IsDefault"
        />
        <vm-text-field
            class="mt-4 mb-4"
            variant="outlined"
            label="Layer Order"
            v-model.number="props.courseMapboxLayer.LayerOrder"
            type="number"
        />

        <div class="button-container">
            <vm-button
                class="mr-2"
                variant="text"
                primaryColor="var(--triadic2-600)"
                @click="closeCallback"
            >
                CANCEL
            </vm-button>
            <vm-button
                class="mr-2"
                primaryColor="var(--triadic2-600)"
                @click="saveCallback"
            >
                SAVE
            </vm-button>
        </div>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['courseMapboxLayer', 'saveCallback', 'closeCallback'],
    emits: ['update:courseMapboxLayer'],
    setup(props) {
        return {
            props,
        };
    },
});
</script>
<style scoped>
.button-container {
    display: flex;
    justify-content: right;
}
</style>
