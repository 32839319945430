<template>
    <div>
        <vm-text-field
            variant="outlined"
            label="Name"
            v-model="props.courseSchema.name"
        />
        <vm-text-field
            class="mt-4"
            variant="outlined"
            label="Display Name"
            v-model="props.courseSchema.displayName"
        />
        <vm-text-field
            class="mt-4"
            variant="outlined"
            label="Country Code"
            v-model="props.courseSchema.countryCode"
        />

        <vm-text-field
            class="mt-4"
            variant="outlined"
            label="TAG"
            v-model="props.courseSchema.tag"
        />

        <vm-switch
            class="mt-4 mb-4"
            label="Is Player App"
            v-model="props.courseSchema.isPlayerApp"
        />

        <div class="button-container">
            <vm-button
                class="mr-2"
                variant="text"
                primaryColor="var(--triadic2-600)"
                @click="closeCallback"
            >
                CANCEL
            </vm-button>
            <vm-button
                class="mr-2"
                primaryColor="var(--triadic2-600)"
                @click="saveCallback"
            >
                SAVE
            </vm-button>
        </div>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['courseSchema', 'saveCallback', 'closeCallback'],
    setup(props: any) {
        return {
            props,
        };
    },
});
</script>

<style scoped>
.button-container {
    display: flex;
    justify-content: right;
}
</style>
