<template>
    <div class="issue-tracker" ref="issueTracker">
        <div class="developer-track-container">
            <div
                class="developer-track"
                v-for="(developer, i) in developers"
                :key="i"
            >
                <div class="developer-header">
                    {{ developer.name }}
                </div>
                <vm-card
                    class="issue in-progress-issue"
                    v-for="issue in developer.issues.filter(
                        (x) => x.labels.indexOf('In Progress') !== -1,
                    )"
                    :key="issue.id"
                    :elevation="4"
                >
                    <a target="_blank" :href="issue.web_url">
                        <vm-card-header class="in-progress-indicator">
                            In Progress
                        </vm-card-header>
                        <vm-card-content>
                            <div class="mb-4" style="font-size: 1.2rem;">
                                {{ issue.title }}
                            </div>
                            <div
                                class="row jc-space-between flex-wrap mt-2"
                                style="font-size: 0.9rem;"
                            >
                                <div>
                                    <strong class="mr-1"
                                        >Time estimated:</strong
                                    >
                                    {{ issue.time_stats.human_time_estimate }}
                                </div>
                                <div
                                    :style="
                                        issue.time_stats.time_estimate <
                                        issue.time_stats.total_time_spent
                                            ? 'color: #ff4f4f;'
                                            : ''
                                    "
                                >
                                    <strong class="mr-1">Time spent:</strong>
                                    {{
                                        issue.time_stats.human_total_time_spent
                                            ? issue.time_stats
                                                  .human_total_time_spent
                                            : '0m'
                                    }}
                                </div>
                            </div>
                            <!-- <div class="row flex-wrap">
                            <vm-card
                                class="mt-2 mr-2"
                                v-for="(label, i) in issue.labels"
                                :key="i"
                            >
                                <div style="padding: 5px 10px;">
                                    {{ label }}
                                </div>
                            </vm-card>
                        </div> -->
                        </vm-card-content>
                    </a>
                </vm-card>
                <vm-card
                    class="issue in-progress-issue"
                    v-for="issue in developer.issues.filter(
                        (x) =>
                            x.labels.indexOf('To Be Merged') !== -1 ||
                            x.labels.indexOf('To Be Deployed') !== -1,
                    )"
                    :key="issue.id"
                    :elevation="4"
                >
                    <a target="_blank" :href="issue.web_url">
                        <vm-card-header class="completed-indicator">
                            Completed
                        </vm-card-header>
                        <vm-card-content>
                            <div class="mb-4" style="font-size: 1.2rem;">
                                {{ issue.title }}
                            </div>
                            <div
                                class="row jc-space-between flex-wrap mt-2"
                                style="font-size: 0.9rem;"
                            >
                                <div>
                                    <strong class="mr-1"
                                        >Time estimated:</strong
                                    >
                                    {{ issue.time_stats.human_time_estimate }}
                                </div>
                                <div
                                    :style="
                                        issue.time_stats.time_estimate <
                                        issue.time_stats.total_time_spent
                                            ? 'color: #ff4f4f;'
                                            : ''
                                    "
                                >
                                    <strong class="mr-1">Time spent:</strong>
                                    {{
                                        issue.time_stats.human_total_time_spent
                                            ? issue.time_stats
                                                  .human_total_time_spent
                                            : '0m'
                                    }}
                                </div>
                            </div>
                            <!-- <div class="row flex-wrap">
                            <vm-card
                                class="mt-2 mr-2"
                                v-for="(label, i) in issue.labels"
                                :key="i"
                            >
                                <div style="padding: 5px 10px;">
                                    {{ label }}
                                </div>
                            </vm-card>
                        </div> -->
                        </vm-card-content>
                    </a>
                </vm-card>
                <div class="mt-4 ml-2" style="margin-bottom: -10px;">
                    <span style="color: #409dcf; font-weight: 500">
                        Next up
                    </span>
                </div>
                <vm-card
                    v-for="issue in developer.issues.filter(
                        (x) =>
                            x.labels.indexOf('In Progress') === -1 &&
                            x.labels.indexOf('To Be Deployed') === -1 &&
                            x.labels.indexOf('To Be Merged') === -1,
                    )"
                    :key="issue.id"
                    class="issue mt-4 ml-2 mr-2"
                >
                    <a target="_blank" :href="issue.web_url">
                        <vm-card-content>
                            <div class="mb-4">
                                {{ issue.title }}
                            </div>
                            <div
                                class="row jc-space-between flex-wrap mt-2"
                                style="font-size: 0.9rem;"
                            >
                                <div>
                                    <strong class="mr-1"
                                        >Time estimated:</strong
                                    >
                                    {{ issue.time_stats.human_time_estimate }}
                                </div>
                                <div
                                    :style="
                                        issue.time_stats.time_estimate <
                                        issue.time_stats.total_time_spent
                                            ? 'color: #ff4f4f;'
                                            : ''
                                    "
                                >
                                    <strong class="mr-1">Time spent:</strong>
                                    {{
                                        issue.time_stats.human_total_time_spent
                                            ? issue.time_stats
                                                  .human_total_time_spent
                                            : '0m'
                                    }}
                                </div>
                            </div>
                        </vm-card-content>
                    </a>
                </vm-card>
            </div>
            <div class="developer-track">
                <div class="developer-header">
                    To be tested
                </div>
                <vm-card
                    class="in-progress-issue"
                    v-if="testing.length === 0"
                    :elevation="4"
                >
                    <vm-card-content>
                        Up to date
                    </vm-card-content>
                </vm-card>
                <vm-card
                    class="issue in-progress-issue"
                    v-for="issue in testing.filter(
                        (x) => x.labels.indexOf('UAT') !== -1,
                    )"
                    :key="issue.id"
                    :elevation="4"
                >
                    <a target="_blank" :href="issue.web_url">
                        <vm-card-header class="uat-indicator">
                            UAT
                        </vm-card-header>
                        <vm-card-content>
                            <div class="mb-4" style="font-size: 1.2rem;">
                                {{ issue.title }}
                            </div>
                            <div
                                class="row jc-space-between flex-wrap mt-2"
                                style="font-size: 0.9rem;"
                            >
                                <div>
                                    <strong class="mr-1"
                                        >Time estimated:</strong
                                    >
                                    {{ issue.time_stats.human_time_estimate }}
                                </div>
                                <div
                                    :style="
                                        issue.time_stats.time_estimate <
                                        issue.time_stats.total_time_spent
                                            ? 'color: #ff4f4f;'
                                            : ''
                                    "
                                >
                                    <strong class="mr-1">Time spent:</strong>
                                    {{
                                        issue.time_stats.human_total_time_spent
                                            ? issue.time_stats
                                                  .human_total_time_spent
                                            : '0m'
                                    }}
                                </div>
                            </div>
                        </vm-card-content>
                    </a>
                </vm-card>
                <div class="mt-4 ml-2" style="margin-bottom: -10px;">
                    <span style="color: #409dcf; font-weight: 500">
                        Developer testing
                    </span>
                </div>
                <vm-card
                    class="issue in-progress-issue mt-4 ml-2 mr-2"
                    v-for="issue in testing.filter(
                        (x) => x.labels.indexOf('Testing') !== -1,
                    )"
                    :key="issue.id"
                >
                    <a target="_blank" :href="issue.web_url">
                        <vm-card-content>
                            <div class="mb-4">
                                {{ issue.title }}
                            </div>
                            <div
                                class="row jc-space-between flex-wrap mt-2"
                                style="font-size: 0.9rem;"
                            >
                                <div>
                                    <strong class="mr-1"
                                        >Time estimated:</strong
                                    >
                                    {{ issue.time_stats.human_time_estimate }}
                                </div>
                                <div
                                    :style="
                                        issue.time_stats.time_estimate <
                                        issue.time_stats.total_time_spent
                                            ? 'color: #ff4f4f;'
                                            : ''
                                    "
                                >
                                    <strong class="mr-1">Time spent:</strong>
                                    {{
                                        issue.time_stats.human_total_time_spent
                                            ? issue.time_stats
                                                  .human_total_time_spent
                                            : '0m'
                                    }}
                                </div>
                            </div>
                        </vm-card-content>
                    </a>
                </vm-card>
            </div>
        </div>
        <div class="fullscreen-button">
            <vm-action-button
                primaryColor="#fff"
                accentColor="#000"
                isSmall
                :icon="!isFullscreen ? 'far fa-expand' : 'far fa-compress'"
                @click="toggleFullscreen"
            />
        </div>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onBeforeMount } from 'vue';

// axios
import axios from 'axios';

export default defineComponent({
    setup() {
        // properties
        const developers = ref([
            { name: 'David', id: 7971948, issues: [] },
            { name: 'Jake', id: 8160169, issues: [] },
            { name: 'Jamie', id: 2389804, issues: [] },
        ]);
        const testing = ref([]);
        const isFullscreen = ref(false);
        const issueTracker = ref<null | HTMLElement>(null);

        // methods
        const toggleFullscreen = () => {
            // isFullscreen.value = window.innerHeight === screen.height;
            if (isFullscreen.value) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            } else {
                const elem = issueTracker.value;
                if (elem) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    }
                }
            }
            isFullscreen.value = !isFullscreen.value;
        };

        const retrieveGitlabData = async () => {
            const milestone = (
                await axios.get(
                    'https://gitlab.com/api/v4/groups/clere-golf-hub/milestones?private_token=AyQ2pMwzjgC2aFbcNgUu&state=active',
                )
            ).data[0];

            let newTesting = [];

            newTesting = (
                await axios.get(
                    `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=Testing&scope=all&state=opened`,
                )
            ).data;

            newTesting = newTesting.concat(
                (
                    await axios.get(
                        `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=UAT&scope=all&state=opened`,
                    )
                ).data,
            );

            testing.value = newTesting;

            developers.value.forEach((developer) => {
                (async () => {
                    let newIssues = [];
                    newIssues = (
                        await axios.get(
                            `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=${milestone.title}&scope=all&assignee_id=${developer.id}&order_by=relative_position&state=opened`,
                        )
                    ).data;
                    newIssues = newIssues.concat(
                        (
                            await axios.get(
                                `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=To+Be+Deployed&scope=all&assignee_id=${developer.id}&order_by=relative_position&state=opened`,
                            )
                        ).data,
                    );
                    newIssues = newIssues.concat(
                        (
                            await axios.get(
                                `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=To+Be+Merged&scope=all&assignee_id=${developer.id}&order_by=relative_position&state=opened`,
                            )
                        ).data,
                    );
                    developer.issues = newIssues;
                })();
            });
        };

        // lifecycle hooks
        onBeforeMount(() => {
            const updateGitlabDataRecursive = async () => {
                await retrieveGitlabData();
                setTimeout(updateGitlabDataRecursive, 10000);
            };
            updateGitlabDataRecursive();
        });

        return {
            developers,
            isFullscreen,
            toggleFullscreen,
            issueTracker,
            testing,
        };
    },
});
</script>

<style scoped>
a {
    text-decoration: none;
    color: #000;
}

.issue-tracker {
    background: rgb(var(--default-background));
    padding: 30px 30px;
}

.developer-track-container {
    flex-wrap: wrap;
    display: flex;
}

.developer-track {
    flex: 1;
    min-width: 0;
    margin-right: 40px;
}

.developer-track:last-child {
    margin-right: 0;
}

.developer-header {
    font-size: 1.8rem;
    margin-bottom: 10px;
    text-align: center;
}

.issue {
    transition: all 0.1s ease-in-out;
}

.issue:hover {
    transform: scale(1.05);
}

.in-progress-issue {
    margin-top: 20px;
}

.in-progress-indicator {
    background: #37b846;
    color: #fff;
}

.completed-indicator {
    background: #942dad;
    color: #fff;
}

.uat-indicator {
    background: #171fbf;
    color: #fff;
}

.fullscreen-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.card-header {
    padding: 8px 16px;
}

@media only screen and (max-width: 1000px) {
    .developer-track-container {
        flex-direction: column;
    }

    .developer-track {
        margin-right: 0;
        margin-top: 50px;
    }

    .developer-track:first-child {
        margin-top: 0;
    }
}
</style>
