<template>
    <vm-theme>
        <div class="app-wrapper">
            <navbar v-if="$store.getters.isLoggedIn"></navbar>
            <div class="router-view">
                <router-view></router-view>
            </div>
            <clere-footer v-if="$store.getters.isLoggedIn"></clere-footer>
        </div>
    </vm-theme>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

// vue-toastification
import { provideToast, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// components
import Navbar from '@/components/navbar.vue';
import ClereFooter from '@/components/clere-footer.vue';

export default defineComponent({
    components: {
        Navbar,
        ClereFooter,
    },
    setup() {
        // logic
        provideToast({
            timeout: 10000,
            position: POSITION.BOTTOM_CENTER,
        });
    },
});
</script>

<style>
@import './style/core.css';

#app {
    z-index: 1;
}

.app-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.router-view {
    flex: 1;
}
</style>
