
// vue
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

// components
import ClereTitle from '@/components/clere-title.vue';

// services
import { useAuthService } from '@/api/service/auth.service';

export default defineComponent({
    components: {
        ClereTitle,
    },
    setup() {
        // hooks
        const store = useStore();

        // services
        const { getVersion } = useAuthService();

        // properties
        const apiVersion = ref('');
        const isLoading = ref(true); // Explicitly type as Ref<boolean>

        // lifecycle hooks
        onMounted(async () => {
            const res = await getVersion();
            apiVersion.value = res.data.tag;
            isLoading.value = false;
        });

        return {
            store,
            apiVersion,
            isLoading,
        };
    },
});
