<template>
    <vm-container>
        <clere-title>
            Migration requests
        </clere-title>
        <div class="row jc-center" v-if="isLoading">
            <vm-loading-spinner />
        </div>
        <vm-card v-if="dataMigrationSummaries.length === 0 && !isLoading">
            <vm-card-content>
                No data migration requests pending
            </vm-card-content>
        </vm-card>
        <vm-card v-if="dataMigrationSummaries.length > 0 && !isLoading">
            <vm-card-content>
                <vm-card
                    class="mb-2"
                    v-for="migration in filteredDataMigrationSummaries"
                    :key="migration.id"
                >
                    <vm-card-content>
                        <div class="request-wrapper">
                            <div class="row">
                                <div class="lg-text">
                                    {{ migration.sourceEnvironmentName }}
                                </div>
                                <div class="ml-2 mr-2">
                                    <i class="far fa-arrow-right"></i>
                                </div>
                                <div class="lg-text">
                                    {{ migration.targetEnvironmentName }}
                                </div>
                            </div>
                            <div class="request-name ml-2">
                                from {{ migration.requestedByAccountName }}
                            </div>
                        </div>

                        <dropdown
                            class="mt-2"
                            :title="
                                'Course' +
                                    (migration.courseSchemaNames.length
                                        ? 's'
                                        : '')
                            "
                        >
                            <div class="dropdown-courses-wrapper">
                                <div class="course-list mb-2">
                                    <ul class="default-ul sm-text">
                                        <li
                                            v-for="(courseName,
                                            i) in migration.courseSchemaNames"
                                            :key="i"
                                        >
                                            {{ courseName }}
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    class="dropdown-courses-buttons"
                                    v-if="
                                        store.state.accountInfo.permissions.indexOf(
                                            'canAuthorizeDataMigration',
                                        ) !== -1
                                    "
                                >
                                    <vm-button
                                        icon="far fa-times"
                                        :elevation="null"
                                        primaryColor="var(--red-100)"
                                        @click="openDeclineModal(migration.id)"
                                    >
                                        DECLINE
                                    </vm-button>

                                    <vm-button
                                        class="ml-2"
                                        icon="far fa-check"
                                        :elevation="null"
                                        primaryColor="var(--primary-600)"
                                        @click="
                                            openAuthorizeModal(migration.id)
                                        "
                                    >
                                        AUTHORIZE
                                    </vm-button>
                                </div>
                            </div>
                        </dropdown>
                    </vm-card-content>
                </vm-card>
                <div
                    class="pagination"
                    v-if="dataMigrationSummaries.length > 3 && !isLoading"
                >
                    <div class="pagination-wrapper">
                        <div class="row-counter"></div>
                        <div class="page-controls">
                            <div class="current-page">
                                {{ startRowIndex + 1 }}-{{ endRowIndex + 1 }} of
                                {{ dataMigrationSummaries.length }}
                            </div>
                            <div
                                class="page-control-button"
                                @click="goToFirstPage"
                            >
                                <i class="far fa-chevron-double-left"></i>
                            </div>
                            <div
                                class="page-control-button"
                                @click="decrementCurrentPage"
                            >
                                <i class="far fa-chevron-left"></i>
                            </div>
                            <div
                                class="page-control-button"
                                @click="incrementCurrentPage"
                            >
                                <i class="far fa-chevron-right"></i>
                            </div>
                            <div
                                class="page-control-button"
                                @click="goToLastPage"
                            >
                                <i class="far fa-chevron-double-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </vm-card-content>
        </vm-card>
        <div class="mt-4">
            <div class="subtitle">Migration History</div>
            <vm-card>
                <vm-grid
                    ref="migrationHistoryGrid"
                    :config="migrationHistoryConfig"
                    :rowData="historicDataMigrations"
                    :isLoading="isGridLoading"
                />
            </vm-card>
        </div>
    </vm-container>

    <vm-modal ref="declineModal" :isPreventClose="isPushLoading">
        <vm-card-header>Decline migration</vm-card-header>

        <vm-card-content>
            <div class="col ai-center" v-if="isPushLoading">
                <div class="mb-2">
                    Declining migration
                </div>
                <vm-loading-spinner />
            </div>

            <confirm
                :closeModal="closeDeclineModal"
                :confirm="declineMigration"
                primaryColor="var(--red-100)"
                v-if="!isPushLoading"
            >
                Are you sure you want to decline this migration request?
            </confirm>
        </vm-card-content>
    </vm-modal>

    <vm-modal ref="authorizeModal" :isPreventClose="isPushLoading">
        <vm-card-header>Authorize migration</vm-card-header>

        <vm-card-content>
            <div class="col ai-center" v-if="isPushLoading">
                <div class="mb-2">
                    Migrating data
                </div>
                <vm-loading-spinner />
            </div>
            <confirm
                :closeModal="closeAuthorizeModal"
                :confirm="authorizeMigration"
                primaryColor="var(--primary-600)"
                v-if="!isPushLoading"
            >
                Are you sure you want to authorize this migration request?
            </confirm>
        </vm-card-content>
    </vm-modal>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

// vue3-material
import { VmModal } from 'vue3-material';

// hooks
import { useToast } from 'vue-toastification';

// components
import ClereTitle from '@/components/clere-title.vue';
import Dropdown from '@/components/dropdown.vue';
import Confirm from '@/components/confirm.vue';

// services
import { useDataMigrationService } from '@/api/service/data-migration-service';

// configs
import { migrationHistoryConfig } from '@/config/migration-history-config.js';
import { useEnvironmentService } from '@/api/service/environment.service';

export default defineComponent({
    components: {
        ClereTitle,
        Dropdown,
        Confirm,
    },
    setup() {
        // hooks
        const store = useStore();
        const toast = useToast();

        // services
        const {
            dataMigrations,
            dataMigrationSummaries,
            historicDataMigrations,
            getDataMigrationAll,
            getDataMigrationSummaryAll,
            getHistoricDataMigrations,
            migrateData,
            declineMigrateData,
        } = useDataMigrationService();

        const { environments, getEnvironmentAll } = useEnvironmentService();

        // refs
        const authorizeModal = ref<InstanceType<typeof VmModal>>();
        const declineModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const modalTitle = 'Confirmation';
        const currentDataMigrationId = ref(0);
        const currentPage = ref(1);
        const rowsPerPage = 3;
        const isLoading = ref(true);
        const isGridLoading = ref(true);
        const isPushLoading = ref(false);

        // computed
        const totalPages = computed(() => {
            return Math.ceil(dataMigrationSummaries.value.length / rowsPerPage);
        });

        const startRowIndex = computed(() => {
            return Math.ceil((currentPage.value - 1) * rowsPerPage);
        });

        const endRowIndex = computed(() => {
            let endRow = Math.ceil(currentPage.value * rowsPerPage - 1);
            if (endRow > dataMigrationSummaries.value.length) {
                endRow = dataMigrationSummaries.value.length - 1;
            }
            return endRow;
        });

        const filteredDataMigrationSummaries = computed(() => {
            const tmpRows = [...dataMigrationSummaries.value];
            return tmpRows.splice(startRowIndex.value, rowsPerPage);
        });

        // methods
        const refreshHistory = async () => {
            isGridLoading.value = true;
            await getHistoricDataMigrations();
            isGridLoading.value = false;
        };

        const getEnvironmentName = (id: number): string => {
            const tempName = environments.value.find((env) => env.id === id);
            if (tempName) {
                return tempName.name;
            }
            return '';
        };

        const openAuthorizeModal = (dataMigrationId: number) => {
            currentDataMigrationId.value = dataMigrationId;
            authorizeModal.value.show();
        };

        const closeAuthorizeModal = () => {
            authorizeModal.value.hide();
        };

        const openDeclineModal = (dataMigrationId: number) => {
            currentDataMigrationId.value = dataMigrationId;
            declineModal.value.show();
        };

        const closeDeclineModal = () => {
            declineModal.value.hide();
        };

        const authorizeMigration = async () => {
            if (currentDataMigrationId.value) {
                isPushLoading.value = true;
                try {
                    await migrateData(currentDataMigrationId.value);
                    toast.success('Successfully migrated data');
                } catch (e) {
                    window.console.log(e);
                }
                isPushLoading.value = false;
                setTimeout(() => {
                    closeAuthorizeModal();
                });
                isGridLoading.value = true;
                refreshHistory();
            }
        };

        const declineMigration = async () => {
            if (currentDataMigrationId.value) {
                isPushLoading.value = true;
                try {
                    await declineMigrateData(currentDataMigrationId.value);
                    toast.success('Successfully declined migration request');
                } catch (e) {
                    window.console.log(e);
                }
                isPushLoading.value = false;
                setTimeout(() => {
                    closeDeclineModal();
                });
                isGridLoading.value = true;
                refreshHistory();
            }
        };

        const setCurrentPage = (pageIndex: number) => {
            if (pageIndex > 0 && pageIndex <= totalPages.value) {
                currentPage.value = pageIndex;
            }
        };

        const incrementCurrentPage = (): void => {
            setCurrentPage(currentPage.value + 1);
        };

        const decrementCurrentPage = (): void => {
            setCurrentPage(currentPage.value - 1);
        };

        const goToLastPage = (): void => {
            setCurrentPage(totalPages.value);
        };

        const goToFirstPage = (): void => {
            setCurrentPage(1);
        };

        // lifecycle hooks
        onMounted(async () => {
            await getDataMigrationSummaryAll();
            isLoading.value = false;

            await getEnvironmentAll();
            await getDataMigrationAll();
            refreshHistory();
        });

        return {
            store,
            dataMigrations,
            dataMigrationSummaries,
            historicDataMigrations,
            migrationHistoryConfig,
            environments,
            getEnvironmentName,
            authorizeMigration,
            declineMigration,
            authorizeModal,
            declineModal,
            openAuthorizeModal,
            closeAuthorizeModal,
            openDeclineModal,
            closeDeclineModal,
            modalTitle,
            currentDataMigrationId,
            filteredDataMigrationSummaries,
            totalPages,
            startRowIndex,
            endRowIndex,
            incrementCurrentPage,
            decrementCurrentPage,
            goToLastPage,
            goToFirstPage,
            isLoading,
            isGridLoading,
            isPushLoading,
        };
    },
});
</script>

<style scoped>
.request-wrapper {
    display: flex;
    align-items: flex-end;
}

.request-name {
    font-size: 0.9rem;
    color: #666;
}

.dropdown-courses-wrapper {
    display: flex;
    justify-content: space-between;
}

.dropdown-courses-buttons {
    display: flex;
    align-items: flex-end;
}

.course-list {
    flex: 1;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
}

.pagination > .pagination-wrapper > .page-controls {
    color: #222;
    display: flex;
}

.pagination > .pagination-wrapper > .page-controls > .current-page {
    padding: 0 10px;
}

.pagination > .pagination-wrapper > .page-controls > .page-control-button {
    color: #888;
    padding: 0 3px;
    margin: 0 10px;
    cursor: pointer;
    transition-duration: 0.1s;
}

.pagination
    > .pagination-wrapper
    > .page-controls
    > .page-control-button:hover {
    color: var(--color-secondary);
    transition-duration: 0.1s;
}

.subtitle {
    font-size: 1.2rem;
    margin-bottom: 15px;
}
</style>
