<template>
    <div class="clere-title">
        <slot></slot>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped>
.clere-title {
    font-size: 1.5rem;
    font-weight: 400;
    color: #222;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-border);
}
</style>
