<template>
    <div class="account-menu" @click="toggleMenu">
        <div class="account-icon">
            {{ initials }}
        </div>
        <div class="account-arrow">
            <i class="fas fa-caret-down"></i>
        </div>
    </div>

    <teleport to="body">
        <div
            class="account-menu-overlay"
            :class="{ 'account-menu-overlay-closed': !isVisible }"
        >
            <div class="links">
                <div class="account-details">
                    Signed in as
                    <strong style="white-space: nowrap;">
                        {{ store.state.accountInfo?.fullName }}
                    </strong>
                </div>
                <div class="divider"></div>
                <div class="link" @click="routeTo('/settings')">
                    Settings
                </div>
                <div class="link" @click="logOut">
                    Log out
                </div>
            </div>
        </div>

        <div
            class="account-overlay-background"
            :class="{ hidden: !isVisible }"
            @click="toggleMenu"
        ></div>
    </teleport>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        // hooks
        const store = useStore();
        const router = useRouter();

        // properties
        const isVisible = ref(false);

        // computed
        const initials = computed(() => {
            const names = store.state.accountInfo.fullName.split(' ');
            return names[0][0] + names[1][0];
        });

        // methods
        const toggleMenu = () => {
            isVisible.value = !isVisible.value;
        };

        const routeTo = (path: string) => {
            isVisible.value = false;
            router.push({ path: path });
        };

        const logOut = () => {
            store.commit('removeAuthToken');
            store.commit('removeAccountInfo');
            router.push({ path: '/' });
        };

        return {
            store,
            isVisible,
            toggleMenu,
            routeTo,
            logOut,
            initials,
        };
    },
});
</script>

<style scoped>
.account-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.account-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 1.1rem;
    background: #888;
    border-radius: 50%;
    user-select: none;
}

.account-arrow {
    font-size: 0.8rem;
    margin-left: 5px;
    transition-duration: 0.1s;
}

.account-menu:hover .account-arrow {
    color: #bbb;
}

.account-menu-overlay {
    position: fixed;
    top: 54px;
    right: 38px;
    padding: 5px 0;
    width: 200px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 0.9rem;
    z-index: 2;
    transition-duration: 0.2s;
}

.account-menu-overlay::before {
    content: '';
    position: absolute;
    top: -1px;
    right: 13px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #ccc;
    border-top: 0;
    margin-left: -8px;
    margin-top: -8px;
}

.account-menu-overlay::after {
    content: '';
    position: absolute;
    top: 0;
    right: 13px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    margin-left: -8px;
    margin-top: -8px;
}

.account-menu-overlay-closed {
    pointer-events: none;
    opacity: 0;
    transform: translateY(8px) translateZ(0);
}

.links {
    color: #000;
}

.account-details {
    padding: 5px 20px;
}

.link {
    display: block;
    text-decoration: none;
    color: #000;
    padding: 5px 20px;
    cursor: pointer;
    transition-duration: 0.1s;
}

.link:hover {
    background: #1376e6;
    color: #fff;
    transition-duration: 0.1s;
}

.divider {
    margin: 5px 0;
    border-top: 1px solid var(--color-border);
}

.account-overlay-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition-duration: 0.2s;
    z-index: 1;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}
</style>
