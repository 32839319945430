<template>
    <vm-container>
        <clere-title>
            Tileserver Configs
        </clere-title>
        <div class="mb-3">
            <vm-button primaryColor="var(--triadic2-600)" @click="openModal()">
                ADD TILESERVER CONFIG
            </vm-button>
        </div>

        <vm-card>
            <vm-grid
                :config="gridConfig"
                :rowData="tileserverConfigs"
                :isLoading="isLoading"
            >
            </vm-grid>
        </vm-card>

        <vm-modal ref="tileserverConfigEditorModal">
            <vm-card-header>Tileserver Config Editor</vm-card-header>
            <vm-card-content>
                <TileserverConfigEditor
                    :isEditing="isEditing"
                    :closeCallback="hideModal"
                    :tileserverConfig="tileserverConfig1"
                    :saveCallback="saveTileserverConfig"
                />
            </vm-card-content>
        </vm-modal>
    </vm-container>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted } from 'vue';

// components
import { VmModal } from 'vue3-material';
import TileserverConfigEditor from '@/components/editor/tileserver-config-editor.vue';
import ClereTitle from '@/components/clere-title.vue';
import { CrActions } from '@/components/grid-plugins';

// services
import { useTileserverConfigService } from '@/api/service/tileserver-config.service';

// hooks
import { useToast } from 'vue-toastification';

// configs
import { TileserverConfig } from '@/api/model/tileserver-config.model';

// models
import { tileserverConfigConfig } from '@/config/tileserver-config-config';

export default defineComponent({
    components: {
        ClereTitle,
        TileserverConfigEditor,
    },
    setup() {
        // services
        const {
            tileserverConfigs,
            getTileserverConfig,
            getTileserverConfigAll,
            createTileserverConfig,
            updateTileserverConfig,
            deleteTileserverConfig,
        } = useTileserverConfigService();

        // hooks
        const toast = useToast();

        // refs
        const tileserverConfig1 = ref(new TileserverConfig());
        const tileserverConfigEditorModal = ref<InstanceType<typeof VmModal>>();

        const isEditing = ref(false);
        const isLoading = ref(true);
        const isLoadingEditor = ref(false);

        // methods
        const openModal = () => {
            if (tileserverConfigEditorModal.value) {
                tileserverConfigEditorModal.value.show();
            } else {
                console.error('tileserverConfigEditorModal is not defined');
            }
        };

        const hideModal = () => {
            tileserverConfigEditorModal.value.hide();
        };

        const saveTileserverConfig = async () => {
            if (isEditing.value) {
                await updateTileserverConfig(tileserverConfig1.value);
                tileserverConfig1.value = new TileserverConfig();
            } else {
                await createTileserverConfig(tileserverConfig1.value);
                tileserverConfig1.value = new TileserverConfig();
            }

            isEditing.value = false;

            toast.success('Tileserver Config saved successfully.');

            hideModal();
        };

        const editTileserverConfig = async (a: TileserverConfig) => {
            if (a && a.id) {
                const existingTileserverConfig = await getTileserverConfig(
                    a.id,
                );
                tileserverConfig1.value = existingTileserverConfig;
                isEditing.value = true;
            } else {
                tileserverConfig1.value = new TileserverConfig();
                isEditing.value = false;
            }
            tileserverConfigEditorModal.value.show();
        };

        const showTileserverConfigDelete = async (tileserverConfig: any) => {
            isLoadingEditor.value = true;
            if (confirm('are you sure?'))
                await deleteTileserverConfig(tileserverConfig.id);
            toast.success('Tileserver Config deleted successfully.');
            isLoadingEditor.value = false;
        };

        // lifecycle hooks
        onMounted(async () => {
            isLoading.value = true;
            await getTileserverConfigAll();
            isLoading.value = false;
        });

        const gridConfig = [
            ...tileserverConfigConfig,
            {
                title: 'Actions',
                fitWidth: true,
                cellRenderer: {
                    component: CrActions,
                    exported: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                callback: editTileserverConfig,
                            },
                            {
                                icon: 'fal fa-trash-alt',
                                callback: showTileserverConfigDelete,
                            },
                        ],
                    },
                },
            },
        ];

        return {
            isEditing,
            openModal,
            hideModal,
            tileserverConfigEditorModal,
            isLoading,
            isLoadingEditor,
            tileserverConfig1,
            tileserverConfigs,
            gridConfig,
            saveTileserverConfig,
        };
    },
});
</script>
