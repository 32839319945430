<template>
    <vm-container>
        <clere-title>
            Roles
        </clere-title>
        <div class="mb-3">
            <vm-button primaryColor="var(--triadic2-600)" @click="addRole()">
                ADD ROLE
            </vm-button>
        </div>

        <vm-card>
            <vm-grid
                :config="gridConfig"
                :rowData="roles"
                :isLoading="isLoading"
            >
            </vm-grid>
        </vm-card>
    </vm-container>

    <vm-modal ref="roleEditorModal">
        <vm-card-header>
            Role editor
        </vm-card-header>

        <vm-card-content>
            <role-editor
                :roleName="roleName"
                :role="role"
                :saveCallback="saveRole"
                :closeCallback="hideModal"
                :permArrayToggle="permArrayToggle"
            >
            </role-editor>
        </vm-card-content>
    </vm-modal>

    <vm-modal ref="confirmDeleteModal">
        <vm-card-header>Delete role</vm-card-header>

        <vm-card-content>
            <confirm
                :closeModal="hideDeleteModal"
                :confirm="deleteSelectedRole"
                primaryColor="var(--red-100)"
            >
                Are you sure you want to delete role {{ role.name }}?
            </confirm>
        </vm-card-content>
    </vm-modal>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted } from 'vue';

// vue3-material
import { VmModal } from 'vue3-material';

// components
import RoleEditor from './role-editor.vue';
import ClereTitle from '@/components/clere-title.vue';
import { CrActions } from '@/components/grid-plugins';
import Confirm from '@/components/confirm.vue';

// services
import { useRoleService } from '@/api/service/role.service';
import { usePermissionService } from '@/api/service/permission.service';

// models
import { Role } from '@/api/model/role.model';
import { rolesConfig } from '@/config/roles-config';

export default defineComponent({
    components: {
        RoleEditor,
        ClereTitle,
        Confirm,
    },
    setup() {
        // services
        const { permissions, getPermissionAll } = usePermissionService();

        const {
            roles,
            getRole,
            getRoleAll,
            createRole,
            updateRole,
            deleteRole,
        } = useRoleService();

        // refs
        const roleEditorModal = ref<InstanceType<typeof VmModal>>();
        const confirmDeleteModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const role = ref(new Role());
        const roleName = ref({ name: '' });
        const isEditing = ref(false);
        const permArrayToggle = ref<any[]>([]);
        const isLoading = ref(true);

        // methods
        const openModal = () => {
            roleEditorModal.value.show();
        };

        const hideModal = () => {
            roleEditorModal.value.hide();
        };

        const hideDeleteModal = () => {
            confirmDeleteModal.value.hide();
        };

        const addRole = () => {
            isEditing.value = false;
            role.value = new Role();
            roleName.value.name = '';
            permArrayToggle.value = permissions.value.map((x: any) => {
                return {
                    id: x.id,
                    name: x.name,
                    toggle: false,
                };
            });
            openModal();
        };

        const saveRole = async () => {
            const rolePermArray = [''];
            rolePermArray.shift();
            permArrayToggle.value.forEach((permToggle) => {
                if (permToggle.toggle === true) {
                    rolePermArray.push(permToggle.name);
                }
            });
            role.value.permissions = rolePermArray;
            role.value.name = roleName.value.name;

            if (isEditing.value) {
                await updateRole(role.value);
            } else {
                await createRole(role.value);
            }

            role.value = new Role();
            hideModal();
            isEditing.value = false;
            permArrayToggle.value.forEach((perm) => {
                perm.toggle = false;
            });
        };

        const deleteSelectedRole = async () => {
            await deleteRole(role.value.id);
            hideDeleteModal();
        };

        const showRoleDelete = async (r: Role) => {
            if (r && r.id) {
                const existingRole = await getRole(r.id);
                role.value = existingRole;
                confirmDeleteModal.value.show();
            }
        };

        const editRole = async (r: Role) => {
            const roleId = r.id;
            const rle = roles.value.find((x) => x.id === roleId);
            if (rle) {
                permArrayToggle.value.forEach((perm) => {
                    rle.permissions.forEach((rolePerm) => {
                        if (perm.name === rolePerm) {
                            perm.toggle = true;
                        } else if (rle.permissions.indexOf(perm.name) === -1) {
                            perm.toggle = false;
                        }
                    });
                });
                role.value = rle;
                roleName.value.name = rle.name;
                isEditing.value = true;
                openModal();
            }
        };

        // lifecycle hooks
        onMounted(async () => {
            await getRoleAll();
            await getPermissionAll();
            permArrayToggle.value = permissions.value.map((x: any) => {
                return {
                    id: x.id,
                    name: x.name,
                    toggle: false,
                };
            });
            isLoading.value = false;
        });

        // configs
        const gridConfig = [
            ...rolesConfig,
            {
                title: 'Actions',
                fitWidth: true,
                cellRenderer: {
                    component: CrActions,
                    exported: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                callback: editRole,
                            },
                            {
                                icon: 'fal fa-trash-alt',
                                callback: showRoleDelete,
                            },
                        ],
                    },
                },
            },
        ];

        return {
            isEditing,
            roles,
            roleName,
            gridConfig,
            saveRole,
            editRole,
            addRole,
            role,
            deleteRole,
            permissions,
            permArrayToggle,
            roleEditorModal,
            confirmDeleteModal,
            openModal,
            hideModal,
            hideDeleteModal,
            deleteSelectedRole,
            isLoading,
        };
    },
});
</script>

<style scoped></style>
