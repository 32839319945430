<template>
    <vm-container>
        <div class="row jc-space-between ai-center" v-if="!isEnvSelected">
            <vm-dropdown
                label="Target Environment"
                title="name"
                :options="environments"
                v-model="selectedEnvironmentId"
            />
        </div>

        <div v-else>
            <div class="mb-3">
                <vm-button
                    @click="openModal()"
                    primaryColor="var(--triadic2-600)"
                >
                    Add Mapbox Layer
                </vm-button>

                <vm-button
                    @click="resetTargetEnv()"
                    class="ml-3 outlined-grey"
                    primaryColor="var(--triadic2-600)"
                >
                    RESET
                </vm-button>
            </div>

            <vm-card>
                <vm-grid
                    :config="gridConfig"
                    :isLoading="isLoading"
                    :rowData="mapboxData"
                >
                </vm-grid>
            </vm-card>

            <vm-modal ref="mapboxEditorModal">
                <vm-card-header>Mapbox Layer Editor</vm-card-header>
                <vm-card-content>
                    <MapboxEditor
                        :isEditing="isEditing"
                        :closeCallback="hideModal"
                        :courseMapboxLayer="courseMapboxLayer1"
                        :saveCallback="saveCourseMapboxLayer"
                    />
                </vm-card-content>
            </vm-modal>
        </div>

        <div class="row-center" v-if="isLoadingEditor">
            <vm-loading-spinner :scale="0.5" />
        </div>
    </vm-container>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onBeforeMount, watch } from 'vue';

// components

import MapboxEditor from '@/components/editor/mapbox-editor.vue';
import { VmModal } from 'vue3-material';
import { CrActions } from '@/components/grid-plugins';

// services
import { useEnvironmentService } from '@/api/service/environment.service';
import { useCourseMapboxLayerService } from '@/api/service/course-mapbox-layer.service';

// hooks
import { useToast } from 'vue-toastification';
// configs

import { courseMapboxLayerConfig } from '@/config/course-mapbox-layer-config';

// models
import {
    CourseMapboxLayer,
    CourseMapboxLayer2,
} from '@/api/model/course-mapbox-layer.model';

export default defineComponent({
    components: {
        MapboxEditor,
    },

    setup() {
        // services
        const {
            courseMapboxLayers,
            getCourseMapboxLayer,
            addCourseMapboxLayer,
            updateCourseMapboxLayer,
            deleteCourseMapboxLayer,
        } = useCourseMapboxLayerService();

        const { environments, getEnvironmentAll } = useEnvironmentService();

        // hooks

        const toast = useToast();

        // refs
        const courseMapboxLayer1 = ref<CourseMapboxLayer>();

        const mapboxEditorModal = ref<InstanceType<typeof VmModal>>();

        const isLoading = ref(true);
        const isEditing = ref(false);
        const isEnvSelected = ref(false);
        const isLoadingEditor = ref(false);

        const selectedEnvironmentId = ref(0);

        // temp
        const mapboxData = ref();

        // methods

        const openModal = () => {
            if (mapboxEditorModal.value) {
                mapboxEditorModal.value.show();
            } else {
                console.error('mapboxEditorModal is not defined or null');
            }
        };

        const hideModal = (): void => {
            courseMapboxLayer1.value = new CourseMapboxLayer();
            isEditing.value = false;
            isLoadingEditor.value = false;
            mapboxEditorModal.value.hide();
        };

        const courseMapboxLayer2 = ref<CourseMapboxLayer2>();

        const saveCourseMapboxLayer = async () => {
            if (!courseMapboxLayer1.value) return;
            isLoadingEditor.value = true;

            if (courseMapboxLayer1.value.style) {
                courseMapboxLayer1.value.style = JSON.parse(
                    courseMapboxLayer1.value.style,
                );
            }

            isEditing.value
                ? await updateCourseMapboxLayer(
                      selectedEnvironmentId.value,
                      courseMapboxLayer1.value,
                  )
                : await addCourseMapboxLayer(
                      selectedEnvironmentId.value,
                      courseMapboxLayer1.value,
                  );

            toast.success('Mapbox Layer saved successfully.');
            hideModal();
        };

        const populateTableData = async (): Promise<void> => {
            if (selectedEnvironmentId.value === 0) return;
            isLoading.value = true;
            mapboxData.value = await getCourseMapboxLayer(
                selectedEnvironmentId.value,
            );
            isLoading.value = false;
        };

        const resetTargetEnv = (): void => {
            selectedEnvironmentId.value = 0;
        };

        const showRoleDelete = async (courseMapboxLayer: any) => {
            isLoadingEditor.value = true;
            if (confirm('are you sure?'))
                await deleteCourseMapboxLayer(
                    courseMapboxLayer.id,
                    selectedEnvironmentId.value,
                );
            toast.success('Course mapbox layer deleted successfully.');
            isLoadingEditor.value = false;
        };

        const editCourseMapboxLayer = async (courseMapboxLayer: any) => {
            isEditing.value = true;
            courseMapboxLayer1.value = mapboxData.value.find(
                (x) => x.id === courseMapboxLayer.id,
            );

            if (courseMapboxLayer1.value)
                courseMapboxLayer1.value.style = JSON.stringify(
                    courseMapboxLayer1.value?.style,
                );
            openModal();
        };

        // watch
        watch(
            selectedEnvironmentId,
            async (): Promise<void> => {
                isEnvSelected.value = !isEnvSelected.value;
                await populateTableData();
            },
        );

        // lifecycle

        onBeforeMount(async () => {
            isLoading.value = true;
            courseMapboxLayer1.value = new CourseMapboxLayer();
            await getEnvironmentAll();
            await populateTableData();
            isLoading.value = false;
        });

        // configs
        const gridConfig = [
            ...courseMapboxLayerConfig,
            {
                title: 'Actions',
                fitWidth: true,
                cellRenderer: {
                    component: CrActions,
                    exported: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                callback: editCourseMapboxLayer,
                            },
                            {
                                icon: 'fal fa-trash-alt',
                                callback: showRoleDelete,
                            },
                        ],
                    },
                },
            },
        ];

        return {
            isEditing,
            openModal,
            hideModal,
            mapboxEditorModal,
            isEnvSelected,
            environments,
            selectedEnvironmentId,
            resetTargetEnv,
            isLoading,
            isLoadingEditor,
            courseMapboxLayer1,
            courseMapboxLayers,
            gridConfig,
            saveCourseMapboxLayer,
            mapboxData,
        };
    },
});
</script>

<style scoped>
.outlined-grey {
    background-color: transparent;
    color: grey;
    border: 1px solid grey;
}
</style>
