<template>
    <div class="col ai-center" v-if="isPushLoading">
        <div class="mb-2">
            Migrating data
        </div>
        <vm-loading-spinner />
    </div>
    <div v-if="!isPushLoading">
        <div class="row jc-space-between ai-center">
            <vm-dropdown
                variant="outlined"
                label="Target environment"
                title="name"
                :options="filteredEnvironments"
                v-model="selectedEnvironmentId"
            />
        </div>
        <div class="confirmation-wrapper" v-if="targetEnvironment">
            <div class="warning">
                <div class="row jc-center ai-center mb-4">
                    <div class="environment-box">
                        {{ environment.name }}
                    </div>
                    <div class="environment-arrow ml-4 mr-4">
                        <i class="far fa-arrow-right"></i>
                    </div>
                    <div class="environment-box">
                        {{ targetEnvironment.name }}
                    </div>
                </div>
                <div class="text-center">
                    Are you sure you want to push the following courses to
                    {{ targetEnvironment.tag }}?
                </div>
            </div>
            <div class="row jc-center mt-4">
                <ul class="default-ul sm-text">
                    <li
                        v-for="(courseSchema, i) in courseSchemas.filter(
                            (x) => selectedCourseIds.indexOf(x.id) !== -1,
                        )"
                        :key="i"
                    >
                        {{ courseSchema.displayName }}
                    </li>
                </ul>
            </div>
            <div class="row jc-flex-end mt-4">
                <vm-button
                    variant="text"
                    primaryColor="var(--triadic2-600)"
                    @click="closeModal"
                >
                    CANCEL
                </vm-button>
                <vm-button
                    class="ml-2"
                    :elevation="null"
                    primaryColor="var(--triadic2-600)"
                    @click="triggerPush"
                >
                    {{ !isAuthorized ? 'REQUEST MIGRATION' : 'MIGRATE' }}
                </vm-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, computed } from 'vue';

// hooks
import { useToast } from 'vue-toastification';

// services
import { useStore } from 'vuex';
import { useDataMigrationService } from '@/api/service/data-migration-service';

// models
import { DataMigration } from '@/api/model/data-migration.model';

export default defineComponent({
    props: [
        'environments',
        'selectedCourseIds',
        'courseSchemas',
        'closeModal',
        'environment',
        'pushStartedCallback',
        'pushFinishedCallback',
    ],
    setup(props: any) {
        // hooks
        const toast = useToast();

        // services
        const store = useStore();
        const { createDataMigration } = useDataMigrationService();

        // properties
        const selectedEnvironmentId = ref(0);
        const dataMigration = new DataMigration();
        const isPushLoading = ref(false);

        // computed
        const targetEnvironment = computed(() => {
            return props.environments.find(
                (x: any) => x.id === selectedEnvironmentId.value,
            );
        });

        const isAuthorized = computed(() => {
            return (
                !targetEnvironment.value.isProtected ||
                store.state.accountInfo.permissions.indexOf(
                    'canAuthorizeDataMigration',
                ) !== -1
            );
        });

        const filteredEnvironments = computed(() => {
            return props.environments.filter(
                (x: any) => x.id !== props.environment.id,
            );
        });

        // methods
        const pushMigration = async () => {
            dataMigration.sourceEnvironmentId = props.environment.id;
            dataMigration.targetEnvironmentId = targetEnvironment.value.id;
            dataMigration.courseIdArray = props.courseSchemas
                .filter(
                    (x: any) => props.selectedCourseIds.indexOf(x.id) !== -1,
                )
                .map((x: any) => x.id);
            dataMigration.requestedByAccountId =
                store.state.accountInfo.accountId;

            try {
                await createDataMigration(dataMigration);
                if (isAuthorized.value) {
                    toast.success('Successfully migrated data');
                } else {
                    toast.success('Successfully requested data migration');
                }
            } catch (e) {
                window.console.log(e);
            }
            selectedEnvironmentId.value = 0;
        };

        const triggerPush = async () => {
            isPushLoading.value = true;
            if (props.pushStartedCallback) {
                props.pushStartedCallback();
            }
            try {
                await pushMigration();
            } catch (e) {
                window.console.log(e);
            }
            if (props.pushFinishedCallback) {
                props.pushFinishedCallback();
            }
            isPushLoading.value = false;
        };

        return {
            store,
            selectedEnvironmentId,
            targetEnvironment,
            pushMigration,
            triggerPush,
            isPushLoading,
            isAuthorized,
            filteredEnvironments,
        };
    },
});
</script>

<style scoped>
.environment-title {
    font-size: 1.1rem;
    white-space: nowrap;
    margin-right: 20px;
}
.environment-dropdown {
    width: 100%;
}

.confirmation-wrapper {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--color-border);
}

.warning {
    font-size: 1.1rem;
}

.environment-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid var(--color-border);
    box-shadow: var(--box-shadow);
    font-size: 1.5rem;
    font-weight: 500;
}

.environment-arrow {
    font-size: 2rem;
}
</style>
