<template>
    <vm-container>
        <clere-title>
            Course data migration
        </clere-title>
        <vm-card v-if="environments.length === 0 && !isLoading">
            <vm-card-content>
                No environments available
            </vm-card-content>
        </vm-card>
        <div class="row jc-center" v-if="isLoading">
            <vm-loading-spinner />
        </div>
        <div v-if="!isLoading">
            <vm-card
                class="mb-4"
                v-for="environment in environments"
                :key="environment.id"
            >
                <vm-card-content>
                    <environment-card
                        :environments="environments"
                        :environment="environment"
                    />
                </vm-card-content>
            </vm-card>
        </div>
    </vm-container>
</template>

<script lang="ts">
// vue
import { defineComponent, ref, onMounted } from 'vue';

// components
import EnvironmentCard from './environment-card.vue';
import ClereTitle from '@/components/clere-title.vue';

// services
import { useEnvironmentService } from '@/api/service/environment.service';

export default defineComponent({
    components: {
        EnvironmentCard,
        ClereTitle,
    },
    setup() {
        // services
        const { environments, getEnvironmentAll } = useEnvironmentService();

        // properties
        const isLoading = ref(true);

        // lifecycle hooks
        onMounted(async () => {
            await getEnvironmentAll();
            isLoading.value = false;
        });

        return {
            environments,
            isLoading,
        };
    },
});
</script>

<style scoped></style>
