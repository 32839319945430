<template>
    <div>
        <div class="documentation-header">Overview</div>
        <vm-card class="mb-4">
            <vm-card-header>How to use this documentation</vm-card-header>
            <vm-card-content>
                The primary aim of this documentation is to outline the steps
                which need to be taken in order to process both the satellite
                imagery and digital elevation model (DEM) to be used on the
                Clere Golf Hub, as well as the Clere Golf Player App
            </vm-card-content>
            <vm-card-content>
                Each process has a set of instructions which should be follow in
                order. Screenshots and expected output will provided where
                possible.
            </vm-card-content>
            <vm-card-content>
                <div class="mb-2">
                    Where the command line must be used to complete a task, code
                    blocks will be used:
                </div>
                <code>
                    <pre>$ just like this!</pre>
                </code>
                <div class="mt-2 mb-2">
                    Take note of the
                    <code class="code-block">$</code>, this simply indicates
                    that the following text is a terminal command, do not type
                    this. Similarly you may also see
                    <code class="code-block">#</code>, which indicates that the
                    command must also be executed as a superuser (this usually
                    just means prefixing the command with
                    <code class="code-block">sudo</code>):
                </div>
                <code>
                    <pre># run this command as root</pre>
                </code>
            </vm-card-content>
        </vm-card>
        <vm-card>
            <vm-card-header>Accessing the development server</vm-card-header>
            <vm-card-content>
                You will need to have SSH access to the development environment
                in order to complete these processes. To do this on a Windows
                machine, you will need to install software to allow you to do
                this, a common solution is
                <a
                    class="btn-link-lg"
                    href="https://www.chiark.greenend.org.uk/~sgtatham/putty/"
                    >PuTTY</a
                >
                (<a
                    class="btn-link-lg"
                    href="https://the.earth.li/~sgtatham/putty/latest/w64/putty-64bit-0.74-installer.msi"
                    >download link</a
                >). If you are using macOS or Linux, you simply need to boot a
                terminal and connect to the development server.
            </vm-card-content>
            <vm-card-content>
                <div class="mb-2">
                    To authenticate with the server you must first generate a
                    public/private key pair, to do this run the following
                    command:
                </div>
                <code>
                    <pre>$ ssh-keygen</pre>
                </code>
                <div class="mt-2 mb-2">
                    The terminal will output the following:
                </div>
                <code>
                    <pre>
Generating public/private rsa key pair.
Enter file in which to save the key (/home/your_username/.ssh/id_rsa): </pre
                    >
                </code>
                <div class="mt-2 mb-2">
                    You can leave this field blank, so press return, the
                    terminal will output the following:
                </div>
                <code>
                    <pre>Enter passphrase (empty for no passphrase):</pre>
                </code>
                <div class="mt-2 mb-2">
                    You can also leave this field blank, so press return, the
                    terminal will output the following:
                </div>
                <code>
                    <pre>Enter same passphrase again:</pre>
                </code>
                <div class="mt-2 mb-2">
                    You can also leave this field blank, so press return, the
                    terminal will output something that looks like this:
                </div>
                <code>
                    <pre>
Your identification has been saved in /home/your_username/.ssh/id_rsa
Your public key has been saved in /home/your_username/.ssh/id_rsa.pub
The key fingerprint is:
SHA256:vw3zneMpwMy5slPLHfErir3D7dy9WS4uIM60tJUrXbg your_username@your_host
The key's randomart image is:
+---[RSA 3072]----+
|                 |
|                 |
|                 |
|            .    |
|        S+ + o   |
|        +.% o .  |
|       = X+@ . ..|
|        OoEB*o+=o|
|        o====*B=+|
+----[SHA256]-----+</pre
                    >
                </code>
                <div class="mt-2 mb-2">
                    This mean you have successfully created a public/private key
                    pair, you will need to give the public key to a developer so
                    you can be added to the authorized keys list on the
                    development server. To do this, output the public key like
                    so:
                </div>
                <code>
                    <pre>
$ cat ~/.ssh/id_rsa.pub
ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABgQCgVhPgpFMgnhAxmUNhF6VzWV2paGI0vNdTh4kIB6hSsIJNBOZaA2kwk/GoDv5O4iDgNHcwlUpokx4UyOIbQKibklLCAdJAwe976BK/+DfmyYaAMBgGXzIJkd8ibY5chGM4vpUK0L+dK5+xoZYO/YeCJOgZxpgOHc4ZdsybQes3AHe2t/0D3xEnQylJW0YGV3gBHFJCZNR/1derict+g0OXg4dyycSaBv9CREysQj45ykCDYW16FLuU5lJv8mPYIo0GtWZgrNxrF0wa1odIj48WpGeBuWgcrHbh6UdHZ3fzeoOROBCETBKB6WgYrp6de1zdu3nGjGVwTyxI0Ztz2YG2E5n/jgDjvYdq3NH1mvUcplbLlCV2R2tttzzU5gGdkFSuQXzV/omffBXPN0UfevXQMpwk3LDtccUV07Giac3gHUhIfogN9UtewGTajXlo0WXD8CXi7IlCFCsJqYUCgI5zxJifCFhGAn17yQMA9OA+wSa0OmOHa4U5itcwFuRI7rk= your_username@your_host</pre
                    >
                </code>
                <div class="mt-2 mb-2">
                    Copy and paste the output to a developer. Once this has been
                    completed, you will need to set up your SSH configuration to
                    allow you connect to the development server
                    (<strong>apollo</strong>), run the command:
                </div>
                <code>
                    <pre>
$ echo -e "Host apollo\n\tHostName dev.cleregolfhub.com\n\tUser root\n\tPort 22\n\tIdentityFile ~/.ssh/id_rsa" &gt;&gt; ~/.ssh/config</pre
                    >
                </code>
                <div class="mt-2 mb-2">
                    You should now be ready to connect to
                    <strong>apollo</strong>, test this by running:
                </div>
                <code>
                    <pre>$ ssh apollo</pre>
                </code>
                <div class="mt-2 mb-2">
                    If you see the following message, type
                    <code class="code-block">yes</code> in full then press
                    return:
                </div>
                <code>
                    <pre>
The authenticity of host 'dev.cleregolfhub.com (51.77.117.54)' can't be established.
ED25519 key fingerprint is SHA256:Z1wBaAeVEx5IQJ3k7uML2NPUiTLzMYGdoe8YqxkHVaI.
This key is not known by any other names
Are you sure you want to continue connecting (yes/no/[fingerprint])?</pre
                    >
                </code>
                <div class="mt-2 mb-2">
                    You should now be presented with a terminal prompt that
                    looks like this:
                </div>
                <code>
                    <pre>root@apollo ~ #</pre>
                </code>
                <div class="mt-2">
                    You now have remote SSH access to the development server.
                </div>
            </vm-card-content>
        </vm-card>
    </div>
</template>

<script lang="ts">
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    setup() {
        return {};
    },
});
</script>

<style scoped></style>
